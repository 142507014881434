import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {
  @Input() eventBanner = "";
  defaultBanner = "../../../assets/mercedes1.png";
  volume: boolean = false;
  // Assuming an array of media file URLs
  @Input() itemType : string = '';
  @Input() numberOfImages: number = 0;
  public currentIndex: number = 0;
  date: any;
  @Input() story !: any;
    constructor(private el: ElementRef) { }
   
    ngOnInit(): void {  
      this.date = new Date(this.story?.createdAt *1000);
      
    }
    
    scroll(direction: number) {
      this.currentIndex = (this.currentIndex + direction + this.story.media.length) % this.story.media.length;
      const mediaContainer = this.el.nativeElement.querySelector('.media-container');
      if (mediaContainer ) {
        const scrollTo = this.currentIndex * mediaContainer.clientWidth;
        mediaContainer.scrollLeft = scrollTo;
      }
    }

}