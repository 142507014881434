import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as L from "leaflet";
import { Map, TileLayer } from 'leaflet';
import { AppServicesService } from 'src/app/services/app-services.service';
import { Location } from "@angular/common";
import { Title, Meta } from '@angular/platform-browser';
import { TranslationService } from 'src/app/services/translation.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { AnyAaaaRecord } from 'dns';

declare const require: any
const polyline = require("google-polyline");


@Component({
  selector: 'app-circuit-details-page',
  templateUrl: './circuit-details-page.component.html',
  styleUrls: ['./circuit-details-page.component.scss']
})
export class CircuitDetailsPageComponent implements OnInit, AfterViewInit {

  @ViewChild("2DmapElement") el!: ElementRef<any>;

  @Input() public circuitData: any;
  @Input() public coordinate = [48, 9];
  @Input() public circuitimageData: any;
  @Input() public slides: any;
  @Input() public navFromLanding: boolean = false;


  @Output() public goToHomePage = new EventEmitter<any>();

  translationData = [];
  languageId: string|null;
  isPageNotFound = false;
  language = '';
  circuitDataContainer: any;
  moveCarousel:string = 'left'

  constructor(
    private sanitizer: DomSanitizer,
    private appservice: AppServicesService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private titleService: Title, private metaService: Meta,
    private translationService: TranslationService,
    private languageService: LanguageService
  ) {
      this.languageId = this.route.snapshot.paramMap.get('languageId');
      if (this.languageId && environment.supported_language.includes(this.languageId)) {
        this.isPageNotFound = false
      } else {
        this.isPageNotFound = true;
      }

    let access_token: any;
    this.route.paramMap.subscribe((paramMap) => {
      const nameParam = paramMap.get('name');
      this.isUser = localStorage.getItem('userLoggedIn');
      access_token = localStorage.getItem('access_token');
      this.isUserLoggedIn = localStorage.getItem('isUserLoggedIn');

      if (!access_token && !this.isUser && !this.isUserLoggedIn) {
        this.language = this.languageService.getLanguage();
        localStorage.setItem('routeUrl', '/'+this.language+'/explore/circuit/'+nameParam);
        this.router.navigateByUrl('/temp-login');
      }
    });
  }

  private barWidth = 40;
  private barHeight = 40;
  private barMargin = -15;
  private startLineWidth = 50;
  private startLineHeight = 50;

  mapInstance: any;
  tileLayer: any;
  polylineDraw: any;
  isAnchorDisable: boolean = true;
  safeImg: any;
  isReadMore: boolean = false;
  access_Token: any;
  guid: any;
  trackPolylineDraw: any;
  emittedTrackData: any;
  group: any;
  barIcon: any;
  marker: any;
  selectedTrack:any;
  isUser:any;
  isUserLoggedIn:any;
  showNotFound:any = false;
  errorMessage = "Circuit not found!"

  setSEOData(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event: any) {

    this.circuitDataContainer = document.getElementById('circuit-title-reg');
    let Scrolcontainer = document.getElementById('scrollCont')?.getBoundingClientRect().top;
    let tourCover = document.getElementById('tourCoverContent')?.getBoundingClientRect().top;
    if(tourCover && Scrolcontainer){
      let difference = tourCover-Scrolcontainer
      console.log(difference)
      if(difference < 90 && !this.circuitDataContainer.classList.contains('header-sticky')){
        this.circuitDataContainer.classList.add('header-sticky');
      } 
      if(difference > 90){
        this.circuitDataContainer.classList.remove('header-sticky')
      }
    }
    
    
  }


  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    
    this.circuitimageData = ""
    if (!this.circuitData) {
      this.route.paramMap.subscribe((paramMap: any) => {
        const nameParam = paramMap.get('name');
        this.guid = nameParam;
      });

      this.appservice.dummyToken().subscribe({
        next: (data: any) => {
          if (data['access_token']) {
            this.access_Token = data.access_token;
            if(this.guid){
              this.getCIrcuitData();
            }
          }
        },
      });      
    }
  }

  ngAfterViewInit() {
    if (this.circuitData) {
      this.setUpLeaflet();
    }
  }

  getCIrcuitData() {
    this.appservice
      .getCircuit(this.guid, this.access_Token)
      .subscribe((data: any) => {
        let description = data.description ? data.description : data.name
        this.setSEOData(data.name, description);
        this.circuitData = data;
        let circuitcoordinate = data.location.split(",");
        this.coordinate = [circuitcoordinate[0], circuitcoordinate[1]];
        this.downloadBlobImage();
        if (!this.navFromLanding) {
          this.setUpLeaflet();
          this.updateTrackInfo();
        }
      },
      (error) => {
        if(error.error['error-code']){
         this.showNotFound = true;
        }
      },);
  }

  downloadBlobImage(url?: any) {
    if (this.circuitData && this.circuitData.media) {
      this.appservice.getBlobImage(this.circuitData.media.image[0].originalUrl, this.access_Token).subscribe({
        next: (blob: any) => {
          // Convert blob to base64

          const reader = new FileReader();

          reader.readAsDataURL(blob);

          reader.onloadend = () => {
            this.circuitimageData = reader.result?.toString()
          };
        }
      });
    } else {

    }

  }


  updateTrackInfo() {
    this.slides = this.circuitData.layouts?.map((item: any) => {
      if (typeof item.encodedGpsPoints == "string") {
        item.encodedGpsPoints = polyline.decode(item.encodedGpsPoints);
      }
      item.trackLength = item.trackLength / 1000;
      item.circuitGuid = this.guid;
      item.fill = "none";
      item.path = "#767676";
      item.updatedDate = new Date(item.updatedDate).toLocaleDateString(
        "de-DE",
        { year: "numeric", month: "2-digit", day: "2-digit" }
      );
      return item;
    });
    let firstElement ={
      name:'Show all',
      fill: 'none',
      path:'#767676',
      encodedGpsPoints:polyline.decode("enl~FnravOcj@ooAbRknA|bAzaC}j@~[")
    }
    this.slides.unshift(firstElement);
  }

  setUpLeaflet() {
    this.mapInstance = new Map(this.el?.nativeElement, {
      zoomControl: true,
      zoom: 11,
      worldCopyJump: true,
      center: [this.coordinate[0], this.coordinate[1]],
      maxBounds: new L.LatLngBounds([
        [-90, -180],
        [90, 180],
      ]),
    });

    this.mapInstance?.zoomControl.setPosition("bottomright");

    // create + add tile layer to map
    this.tileLayer = new TileLayer(
      'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
      {
        maxZoom: 16,
        minZoom: 8,
        attribution: "",
      }
    ).addTo(this.mapInstance);

    this.drawCircuitOnMap();

  }

  drawCircuitOnMap() {
    let windowWidth = window.innerWidth;
    if (this.circuitData && this.circuitData.encodedPolyline) {
      let polylineData = polyline.decode(this.circuitData.encodedPolyline)
      this.polylineDraw = L.polyline(polylineData, {
        color: '#b21e23', fillColor: "#b21e23",
        fill: true, fillOpacity: 0.5
      }).addTo(this.mapInstance);
      this.mapInstance.getRenderer(this.mapInstance).options.padding = 100;
    } else if (this.circuitData && this.circuitData.area) {
      let mapCenter = {
        lat: 0,
        lng: 0
      };
      let array = this.circuitData.location.split(',');
      mapCenter.lat = parseFloat(array[0]);
      mapCenter.lng = parseFloat(array[1]);
      let circleData = this.circuitData.area * 1000;
      this.polylineDraw = L.circle([mapCenter['lat'], mapCenter['lng']], circleData, {
        color: '#b21e23', fillColor: "#b21e23",
        fill: true, fillOpacity: 0.5
      }).addTo(this.mapInstance);
    }
    const resizeObserver = new ResizeObserver(() => {
      this.mapInstance.invalidateSize();
    });
    const mapDiv = document.getElementById("2DmapElement");
    if (mapDiv) {
      resizeObserver.observe(mapDiv);
    }
    this.polylineDraw.guid = this.circuitData.guid;
    this.polylineDraw.name = this.circuitData.name;
    this.polylineDraw.coordinates = this.circuitData.coordinates;

    if (windowWidth < 750) {
      this.mapInstance.fitBounds(this.polylineDraw.getBounds(), {
        paddingTopLeft: [0, 0],
        paddingBottomRight: [0, 60],
      });
    } else {
      this.mapInstance.fitBounds(this.polylineDraw.getBounds(), {
        paddingTopLeft: [600, 120],
        paddingBottomRight: [100, 50],
      });
    }

    window.addEventListener("resize", () => {
      this.mapInstance.fitBounds(this.polylineDraw.getBounds())

    }
    );
  }

  drawTrackOnMap() {
    let fillColor = "#d9252b";
    this.trackPolylineDraw = L.polyline(this.emittedTrackData?.encodedGpsPoints, {
      color: '#b21e23', fillColor: "#b21e23",
      fill: false, fillOpacity: 0.5
    }).addTo(this.mapInstance);
    this.emittedTrackData?.sectorPoints.forEach((data: any, i: any) => {
      this.rotateleafletDomMarker(this.mapInstance, data, i, fillColor);
    });
  }

  goHome() {
    if (this.navFromLanding) {
      this.goToHomePage.emit();
    } else {
      this.location.go('/'+this.language+'/explore');
      window.location.reload();
      localStorage. removeItem('filter');
    }
  }

  readMore() {
    this.isReadMore = !this.isReadMore
  }

  getemitTrackData(event:any) {
    this.selectedTrack = event.index;
    if (this.trackPolylineDraw) {
      this.trackPolylineDraw.remove();
    }
    if (this.marker) {
      this.marker.remove();
      this.marker = null
    }
    this.emittedTrackData = event;
    this.drawTrackOnMap();
  }

  rotateleafletDomMarker(map: any, data: any, index: any, color: any) {
    let rotate;
    let transform = 0.0;
    if (data[3] == 0) {
      data[3] = 130;
      rotate = 36;
    } else {
      rotate = 270;
    }
    let domIconElement = document.createElement("div");

    // set the anchor using margin css property depending on the content's (svg element below) size
    // to make sure that the icon's center represents the marker's geo positon

    // add content to the element
    if (index >= 1) {
      domIconElement.setAttribute("id", "bar-image");
      domIconElement.style.margin = `${this.barMargin}px`;
      domIconElement.innerHTML = `<svg width="${this.barWidth}px" height="${this.barHeight}px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Line 5</title>
    <defs>
        <path d="M9.71777003,28.6387665 L29.71777,12.6387665" id="path-1"></path>
        <filter x="-44.1%" y="-55.1%" width="188.1%" height="210.1%" filterUnits="objectBoundingBox" id="filter-2">
            <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feMorphology radius="2" operator="erode" in="SourceAlpha" result="shadowInner"></feMorphology>
            <feOffset dx="0" dy="0" in="shadowInner" result="shadowInner"></feOffset>
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"></feComposite>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g id="Line-5" transform="translate(19.717770, 20.638767) rotate(36.640192) translate(-19.717770, -20.638767) ">
            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
            <use stroke="${color}" stroke-width="5" xlink:href="#path-1"></use>
        </g>
    </g>
</svg>`;
      domIconElement.style.transform = "rotate(" + data[3] + "deg)";

    } else {
      domIconElement.setAttribute("id", "start-image");
      domIconElement.style.margin = `-15px`;
      domIconElement.innerHTML = `<svg width="${this.startLineWidth}px" height="${this.startLineHeight}px" viewBox="0 0 40 61" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>start_line</title>
      <defs>
          <filter x="-31.0%" y="-17.0%" width="161.9%" height="133.9%" filterUnits="objectBoundingBox" id="filter-1">
              <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
              <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
              <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.704210081 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
              <feMerge>
                  <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                  <feMergeNode in="SourceGraphic"></feMergeNode>
              </feMerge>
          </filter>
          <path d="M0.697164744,2.92834653 L11.0978585,2.92834653 L9.73400514,0 L15.6053632,3.49486192 L9.91004728,7.05334489 L11.0978585,4.40087676 L0.697164744,4.40087676 C0.232388941,4.39120411 0,4.14335558 0,3.65735196 C0,3.17134834 0.232388941,2.92834653 0.697164744,2.92834653 Z" id="path-2"></path>
          <path d="M6.24041626,0 C7.38924466,0 8.32055502,0.931309323 8.32055502,2.08013875 L8.32055502,43.6829138 C8.32055502,44.8317428 7.38924466,45.7630526 6.24041626,45.7630526 L2.08013875,45.7630526 C0.931309843,45.7630526 0,44.8317428 0,43.6829138 L0,2.08013875 C0,0.931309323 0.931309843,0 2.08013875,0 L6.24041626,0 Z" id="path-4"></path>
          <path d="M4.16027751,0 L4.16027751,4.16027751 L0,4.16027751 L0,2.08013875 C0,0.931309323 0.931309843,0 2.08013875,0 L4.16027751,0 Z" id="path-6"></path>
          <polygon id="path-8" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <path d="M2.08013875,0 C3.22896715,0 4.16027751,0.931309323 4.16027751,2.08013875 L4.16027751,4.16027751 L0,4.16027751 L0,0 L2.08013875,0 Z" id="path-10"></path>
          <polygon id="path-12" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-14" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-16" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-18" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-20" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-22" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-24" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-26" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-28" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-30" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-32" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-34" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-36" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-38" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-40" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-42" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <polygon id="path-44" points="0 0 4.16027751 0 4.16027751 4.16027751 0 4.16027751"></polygon>
          <path d="M4.16027751,0 L4.16027751,4.16027751 L2.08013875,4.16027751 C0.931309843,4.16027751 0,3.22896767 0,2.08013875 L0,0 L4.16027751,0 Z" id="path-46"></path>
          <path d="M4.16027751,0 L4.16027751,2.08013875 C4.16027751,3.22896767 3.22896715,4.16027751 2.08013875,4.16027751 L0,4.16027751 L0,0 L4.16027751,0 Z" id="path-48"></path>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="start_line" filter="url(#filter-1)"  transform="translate(19.717770, 20.638767) rotate(${rotate}) translate(-19.717770, -20.638767)">
              <g id="Group-3" transform="translate(10.223564, ${transform})">
                  <mask id="mask-3" fill="white">
                      <use xlink:href="#path-2"></use>
                  </mask>
                  <g id="Clip-2"></g>
                  <polygon id="Fill-1" fill="#D9252B" mask="url(#mask-3)" points="-1.04006938 -1.04006938 16.6454326 -1.04006938 16.6454326 8.09341427 -1.04006938 8.09341427"></polygon>
              </g>
              <g id="Group-73" transform="translate(0.000000, 1.368242)">
                  <g id="Group-6" transform="translate(0.000000, 0.000000)">
                      <mask id="mask-5" fill="white">
                          <use xlink:href="#path-4"></use>
                      </mask>
                      <g id="Clip-5"></g>
                      <polygon id="Fill-4" fill="#D8D8D8" mask="url(#mask-5)" points="-1.04006938 -1.04006938 9.3606244 -1.04006938 9.3606244 46.803122 -1.04006938 46.803122"></polygon>
                  </g>
                  <g id="Group-9" transform="translate(0.000000, 0.000000)">
                      <mask id="mask-7" fill="white">
                          <use xlink:href="#path-6"></use>
                      </mask>
                      <g id="Clip-8"></g>
                      <polygon id="Fill-7" fill="#FFFFFF" mask="url(#mask-7)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-12" transform="translate(0.000000, 4.160278)">
                      <mask id="mask-9" fill="white">
                          <use xlink:href="#path-8"></use>
                      </mask>
                      <g id="Clip-11"></g>
                      <polygon id="Fill-10" fill="#000000" mask="url(#mask-9)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-15" transform="translate(4.160278, 0.000000)">
                      <mask id="mask-11" fill="white">
                          <use xlink:href="#path-10"></use>
                      </mask>
                      <g id="Clip-14"></g>
                      <polygon id="Fill-13" fill="#000000" mask="url(#mask-11)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-18" transform="translate(4.160278, 4.160278)">
                      <mask id="mask-13" fill="white">
                          <use xlink:href="#path-12"></use>
                      </mask>
                      <g id="Clip-17"></g>
                      <polygon id="Fill-16" fill="#FFFFFF" mask="url(#mask-13)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-21" transform="translate(0.000000, 8.320555)">
                      <mask id="mask-15" fill="white">
                          <use xlink:href="#path-14"></use>
                      </mask>
                      <g id="Clip-20"></g>
                      <polygon id="Fill-19" fill="#FFFFFF" mask="url(#mask-15)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-24" transform="translate(4.160278, 8.320555)">
                      <mask id="mask-17" fill="white">
                          <use xlink:href="#path-16"></use>
                      </mask>
                      <g id="Clip-23"></g>
                      <polygon id="Fill-22" fill="#000000" mask="url(#mask-17)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-27" transform="translate(0.000000, 12.480833)">
                      <mask id="mask-19" fill="white">
                          <use xlink:href="#path-18"></use>
                      </mask>
                      <g id="Clip-26"></g>
                      <polygon id="Fill-25" fill="#000000" mask="url(#mask-19)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-30" transform="translate(4.160278, 12.480833)">
                      <mask id="mask-21" fill="white">
                          <use xlink:href="#path-20"></use>
                      </mask>
                      <g id="Clip-29"></g>
                      <polygon id="Fill-28" fill="#FFFFFF" mask="url(#mask-21)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-33" transform="translate(0.000000, 16.641110)">
                      <mask id="mask-23" fill="white">
                          <use xlink:href="#path-22"></use>
                      </mask>
                      <g id="Clip-32"></g>
                      <polygon id="Fill-31" fill="#FFFFFF" mask="url(#mask-23)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-36" transform="translate(4.160278, 16.641110)">
                      <mask id="mask-25" fill="white">
                          <use xlink:href="#path-24"></use>
                      </mask>
                      <g id="Clip-35"></g>
                      <polygon id="Fill-34" fill="#000000" mask="url(#mask-25)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-39" transform="translate(0.000000, 20.801388)">
                      <mask id="mask-27" fill="white">
                          <use xlink:href="#path-26"></use>
                      </mask>
                      <g id="Clip-38"></g>
                      <polygon id="Fill-37" fill="#000000" mask="url(#mask-27)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-42" transform="translate(4.160278, 20.801388)">
                      <mask id="mask-29" fill="white">
                          <use xlink:href="#path-28"></use>
                      </mask>
                      <g id="Clip-41"></g>
                      <polygon id="Fill-40" fill="#FFFFFF" mask="url(#mask-29)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-45" transform="translate(0.000000, 24.961665)">
                      <mask id="mask-31" fill="white">
                          <use xlink:href="#path-30"></use>
                      </mask>
                      <g id="Clip-44"></g>
                      <polygon id="Fill-43" fill="#FFFFFF" mask="url(#mask-31)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-48" transform="translate(4.160278, 24.961665)">
                      <mask id="mask-33" fill="white">
                          <use xlink:href="#path-32"></use>
                      </mask>
                      <g id="Clip-47"></g>
                      <polygon id="Fill-46" fill="#000000" mask="url(#mask-33)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-51" transform="translate(0.000000, 29.121943)">
                      <mask id="mask-35" fill="white">
                          <use xlink:href="#path-34"></use>
                      </mask>
                      <g id="Clip-50"></g>
                      <polygon id="Fill-49" fill="#000000" mask="url(#mask-35)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-54" transform="translate(4.160278, 29.121943)">
                      <mask id="mask-37" fill="white">
                          <use xlink:href="#path-36"></use>
                      </mask>
                      <g id="Clip-53"></g>
                      <polygon id="Fill-52" fill="#FFFFFF" mask="url(#mask-37)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-57" transform="translate(0.000000, 33.282220)">
                      <mask id="mask-39" fill="white">
                          <use xlink:href="#path-38"></use>
                      </mask>
                      <g id="Clip-56"></g>
                      <polygon id="Fill-55" fill="#FFFFFF" mask="url(#mask-39)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-60" transform="translate(4.160278, 33.282220)">
                      <mask id="mask-41" fill="white">
                          <use xlink:href="#path-40"></use>
                      </mask>
                      <g id="Clip-59"></g>
                      <polygon id="Fill-58" fill="#000000" mask="url(#mask-41)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-63" transform="translate(0.000000, 37.442498)">
                      <mask id="mask-43" fill="white">
                          <use xlink:href="#path-42"></use>
                      </mask>
                      <g id="Clip-62"></g>
                      <polygon id="Fill-61" fill="#000000" mask="url(#mask-43)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-66" transform="translate(4.160278, 37.442498)">
                      <mask id="mask-45" fill="white">
                          <use xlink:href="#path-44"></use>
                      </mask>
                      <g id="Clip-65"></g>
                      <polygon id="Fill-64" fill="#FFFFFF" mask="url(#mask-45)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-69" transform="translate(0.000000, 41.602775)">
                      <mask id="mask-47" fill="white">
                          <use xlink:href="#path-46"></use>
                      </mask>
                      <g id="Clip-68"></g>
                      <polygon id="Fill-67" fill="#FFFFFF" mask="url(#mask-47)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
                  <g id="Group-72" transform="translate(4.160278, 41.602775)">
                      <mask id="mask-49" fill="white">
                          <use xlink:href="#path-48"></use>
                      </mask>
                      <g id="Clip-71"></g>
                      <polygon id="Fill-70" fill="#000000" mask="url(#mask-49)" points="-1.04006938 -1.04006938 5.20034689 -1.04006938 5.20034689 5.20034689 -1.04006938 5.20034689"></polygon>
                  </g>
              </g>
          </g>
      </g>
  </svg>`;
      domIconElement.style.transform = "rotate(" + data[3] + "deg)";
    }

    this.barIcon = L.divIcon({
      html: domIconElement,
      className: "sector",
    });
    this.marker = L.marker([data[0], data[1]], { icon: this.barIcon });
    this.marker.type = "sector";
    this.marker.name = 'marker';
    this.mapInstance.addLayer(this.marker);
  }

  goLeft(){
    this.moveCarousel = 'left' + Math.floor(Math.random() * 10);    
  }

  goRight(){
    this.moveCarousel = 'right' + Math.floor(Math.random() * 10);
  }

  disableArrowIcon(direction:string){
    let arrowLeft = document.getElementById('leftIcon')
    let arrowRight = document.getElementById('rightIcon')

    if(direction === 'left'){
      arrowRight?.classList.remove('disable-arrow');
      arrowLeft?.classList.add('disable-arrow');
    } else{
      arrowRight?.classList.add('disable-arrow');
      arrowLeft?.classList.remove('disable-arrow');
    }
  }


}