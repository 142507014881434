<ng-container *ngIf="isTranslationDone && translationData.length">
<ng-container *ngIf="content | customTranslation: translationData; else elseBlock">

    <section class="legal-page-container" [innerHTML]="content | customTranslation: translationData">
    
    </section>
    </ng-container>
    <ng-template #elseBlock>
        <section>
            <ng-container *ngIf="!isNotFound">
                <app-not-found-page [contentNotFound]="true"></app-not-found-page>
    
            </ng-container>
        </section>
    </ng-template>
    <ng-container *ngIf="isNotFound">
        <app-not-found-page></app-not-found-page>
    </ng-container>
</ng-container>