import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tags-component',
  templateUrl: './tags-component.component.html',
  styleUrls: ['./tags-component.component.scss']
})
export class TagsComponentComponent implements OnInit {
  @Input() tagTitle : any;
  @Input() tagClass : any;
  constructor() { }

  ngOnInit(): void {
  }

}