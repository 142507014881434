import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LanguageService } from '../services/language.service';

interface CustomWindow extends Window {
  // SetPopUpInfo: any;
}

const createUnityInstance = (window as any)['createUnityInstance'];
declare const window: CustomWindow;

@Component({
  selector: 'app-track-loader',
  templateUrl: './track-loader.component.html',
  styleUrls: ['./track-loader.component.scss'],
})
export class TrackLoaderComponent implements OnInit {
  origin: string | null = '';
  destination: string | null = '';
  guid: string | null = '';
  guidLength: number = 36;
  isUserLoggedIn: any;
  routerUrls: any = [];
  isUser: any;
  languageId: string|null;
  isPageNotFound = false;
  language = '';
  access_token:any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private languageService: LanguageService
  ) {
    this.languageId = this.route.snapshot.paramMap.get('languageId');
      if (this.languageId && environment.supported_language.includes(this.languageId)) {
        this.isPageNotFound = false
      } else {
        this.isPageNotFound = true;
      }

    this.route.paramMap.subscribe((paramMap) => {
      this.isUser = localStorage.getItem('userLoggedIn');
      this.access_token = localStorage.getItem('access_token');
      this.isUserLoggedIn = localStorage.getItem('isUserLoggedIn');
      let tourName = localStorage.getItem('emotionTourName');
      let tourGuid = localStorage.getItem('emotionTourguid');
      if (!tourName && !tourGuid) {
        const nameParam = paramMap.get('name');
        if (nameParam && nameParam !== 'login') {
          this.destination = nameParam.substring(
            0,
            nameParam.length - this.guidLength - 1
          ); // -1 is added to remove the "-" at the end of track name
          this.guid = nameParam.slice(nameParam.length - this.guidLength);
          this.routerUrls.push(nameParam);
        }
      
      } else if (tourName && tourGuid && paramMap.get('name') !== 'login') {
        this.destination = tourName;
        this.guid = tourGuid;
        this.routerUrls.push(this.destination);
      }
    });

    if (!this.access_token && !this.isUser && !this.isUserLoggedIn) {
      this.language = this.languageService.getLanguage();
      localStorage.setItem('routeUrl', '/'+this.language+'/explore/emotion-tour/'+this.routerUrls[0]);
      this.router.navigateByUrl('/temp-login');
    }
  }

  ngOnInit(): void {
    //This is intentional
  }
}
