<div id="c-s-page">
<div id="c-s-content">
<div id="brand-center">
    <a id="mbbrand" class="header-link" href="nolink">
      <video role="presentation" [preload]="true" [muted]="true" playsinline="" autoplay class="brandhub-header__video" #video>
        <source src="../../../assets/media/original.mp4" type="video/mp4">
      </video>
      <picture class="brandhub-header__logo">
        
        <img src="../../../assets/media/MB-star_n_web.svg" alt="Mercedes-Benz brand logo" class="brandhub-picture brandhub-picture--contain">
      </picture>

    </a>
  </div>
  <h1 id='msg'>Coming soon</h1>
  </div>
</div> 
