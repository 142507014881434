
<div [ngSwitch]="story.itemType">
    <!-- This is Moments Crad component -->
    <div *ngSwitchCase="'MOMENT'">
        <div class="rectangle">
            <div class="image">
                <div class="media-container">
                    <div *ngFor="let media of story.media" class="media-item">
                        <ng-container>
                            <img class="img-fluid" [src]="media.url" alt="Image">
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="info-panel-bottom">
                <h2>{{story.description[0].value}}</h2>
                <div class="rowele">
                    <div class="track tag-item">
                        <span>{{date | date: 'dd.MM.yyyy'}}</span>
                    </div>
                    <div class="track tag-item" *ngIf="!story.location">
                        <span>{{story.location}}</span>
                    </div>

                </div>
            </div>
            <div class="tag-item slideIndex" *ngIf="story.media.length > 1">
                <span class="slideerNumber">{{currentIndex+1}}/{{story.media.length}}</span>
            </div>
            <div *ngIf="story.media.length>1">
                <button (click)="scroll(-1)" class="arrow-button left" *ngIf="this.currentIndex != 0"></button>
                <button (click)="scroll(1)" class="arrow-button right"
                    *ngIf="this.currentIndex != story.media.length-1"></button>
            </div>
        </div>
    </div>

    <!-- This is pending card component -->
    <!-- <div *ngSwitchCase="'pending'">
        <div class="rectangle">
            <div class="img-reg">
                <div class="image" style="background-image: url({{eventBanner?eventBanner:defaultBanner}});">
                </div>
            </div>
            <div class="info-center">
                <span class="Your-race-data-is-in">
                    <p>Your race data is in the works...</p>
                    <p>and we'll give you a shout when it's all set to go</p>

                </span>
            </div>

            <div class="info-panel-bottom">

                <h2 class="story-title">Driving Academy Bilster Berg 2023</h2>

            </div>
        </div>
    </div> -->


    <!-- This is Track race card component  of sharing -->
    <div *ngSwitchCase="'TRACKRACE'">
        <div class="rectangle ">
            <div class="media-container">
                <div class="media-item" *ngFor="let media of story.media;let i = index">
                    <ng-container>
                        <img class="img-fluid" *ngIf="media.mediaType=='image'" [src]="media.url">
                    <video *ngIf="media.mediaType=='VIDEO'" class="img-fluid" [muted]="!volume" autoplay preload="none"
                         [loop]="true" >
                        <source [src]="media.url" type="video/mp4"/>
                    </video>
                    </ng-container>
                </div>
            </div>
            <div class="info-panel-bottom">
                <!-- Event Name -->
                <h2 class="event-title">{{story.raceData.raceName}}</h2>
                <div class="rowele">
                    <div class="circuit tag-item">
                        <!-- lap Count -->
                        <span class="exercise">{{story.raceData.lapCount}} laps</span>
                    </div>

                    <div class="track tag-item">
                        <!-- Race start time -->
                        <div class="star_icon">
                            <span class="icon"></span>
                            <p>{{story.raceData.bestLapDuration}} min</p>
                        </div>
                    </div>
                    <div class="track tag-item">
                        <!-- time and date -->
                        <span>{{raceShareTime | date: 'dd.MM.yyyy'}} . {{raceShareTime | date: 'h.mm'}}</span>
                    </div>
                </div>
                <div class="rowele">
                    <div class="track  tag-item">
                        <!--Track Name-->
                        <span>{{story.raceData.trackName}} </span>
                    </div>
                    <div class="track  tag-item" *ngIf="!story.location">
                        <!-- Location -->
                        <span> {{story.location}} </span>

                    </div>
                </div>
            </div>
            <div class="tag-item slideIndex" *ngIf="story.media.length > 1">
                <span class="slideerNumber">{{currentIndex+1}}/{{story.media.length}}</span>
            </div>
            <div *ngIf="story.media.length>1">
                <button (click)="scroll(-1)" class="arrow-button left" *ngIf="this.currentIndex != 0"></button>
                <button (click)="scroll(1)" class="arrow-button right"
                    *ngIf="this.currentIndex != story.media.length-1"></button>
            </div>
        </div>
    </div>

    <!-- This is Event race card -->
    <div *ngSwitchCase="''">
        <div class="rectangle">
            <div class="img-reg">
                <div class="image" style="background-image: url();">
                </div>
            </div>
            <div class="info-center">

            </div>

            <div class="info-panel-bottom">
                <!-- Event Name -->
                <h2 class="event-title">{{story.raceData.raceName}}</h2>
                <div class="rowele">
                    <div class="circuit tag-item">
                        <!-- exercise type -->
                        <span class="exercise">Best time challenge 2</span>
                    </div>

                    <div class="track tag-item">
                        <!-- Best time -->
                        <div class="star_icon">
                            <span class="icon"></span>
                            <p>1:44.27 min</p>
                        </div>
                    </div>
                    <div class="track tag-item">
                        <!-- time and date -->
                        <span> 23.05.2023 . 14:42</span>
                    </div>
                </div>
                <div class="rowele">
                    <div class="track  tag-item">
                        <!--Track Name-->
                        <span>Bilster Berg ADA Track </span>
                    </div>
                    <div class="track  tag-item">
                        <!-- Location -->
                        <span> Mercedes-AMG GT 63 S E PERFORMANCE </span>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>