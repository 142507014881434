import { Component, OnInit } from '@angular/core';
import { TranslationService } from './services/translation.service';
import { environment } from 'src/environments/environment';
import { LanguageService } from './services/language.service';
import { Router } from '@angular/router';
import { ToastService } from './services/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'route';
  language = 'en-GB'
  translatedArray = [];
  showToast = false;
  toastMessage: any;
  toastType: any;
  position: any;
  leftPosition: any;  
  noAnimation : boolean = false;
  featureFlags: any;
  constructor(private translationService: TranslationService, 
    private languageService: LanguageService, 
    private router: Router,
    private toastService: ToastService){
    const fullURL = window.location.href.split('/');
    
    if (environment.supported_language.includes(fullURL[3])) {
      localStorage.setItem('language', fullURL[3]);      
    } 
    const selectedLang = this.languageService.getLanguage();
    this.language = selectedLang;
    if (environment.supported_language.includes(fullURL[3]) ||
      (fullURL.includes('logout') || fullURL.includes('login') ||
        fullURL.includes('temp-login') || fullURL.includes('verify') || fullURL.includes('anonymize'))) {
      if (selectedLang) {
        this.language = selectedLang;
      } else {
        this.language = environment.supported_language[0];
        localStorage.setItem('language', this.language);
      }
    } else if (fullURL[3] !== '') {
      this.router.navigateByUrl('/' + fullURL[3] + '/404');
    } else if (fullURL[3] === '') {
      this.router.navigateByUrl('/' + this.language + '/home');
    }
  }

  ngOnInit(): void {   
    const isLanguagePresent = this.router.url.split('/')[1] && environment.supported_language.includes(this.router.url.split('/')[1]); 
    const selectedLang = isLanguagePresent ? this.router.url.split('/')[1] : this.language;
    this.getTranslations(selectedLang);
    this.featureFlags = this.translationService.featureFlags;
    this.getToastData();
    let videoStatus = sessionStorage.getItem('videoplayed');

    if(videoStatus === 'true'){
        this.noAnimation = true;
    }
  }

  getToastData(){
    this.toastService.toastDetails.subscribe((status: any) => {
      this.showToast = status.isShown;
      this.toastMessage = status.message;
      this.toastType = status.toastType;
      this.position = status.position;
      this.leftPosition = status.leftPosition;
    });
  }

  getTranslations(languageId: string) {
    this.translationService
      .phraseApiCallForTranslations(languageId)

      .subscribe((allDeTranslationBasedOnlocle) => {   
        for(let i=0; i< allDeTranslationBasedOnlocle.length; i++) {
          this.translatedArray = this.translatedArray.concat(allDeTranslationBasedOnlocle[i]);
        }
        this.translationService.setTranslationData(this.translatedArray);
        this.translationService.sendSelectedLanguage(this.language);
        this.translationService.sendtranslationValue(this.translatedArray);
      });
  }

}
