<ng-container *ngIf="isUserLoggedIn || isUser">
  <ng-container *ngIf="!isPageNotFound">
    
  <div *ngIf="!showNotFound" id="twodmapcontainer">
    <div id="twodmap" class="map-class" #2DmapElement></div>
  </div>
  <div *ngIf="showNotFound">
    <app-no-route></app-no-route>
  </div>
  
  <div (scroll)="scrollHandler($event)" id="scrollCont" class="left-scroll-content">
  <div *ngIf="this.circuitData" class="details-card">
    <div class="circuit-title-reg">
      <div id="circuit-title-reg" class="circuit-header">
        <span (click)="goHome()" class="arrow"></span>
        <h1>{{this.circuitData.name}}</h1>
      </div>
      <div id="tagArea" class="tag-items">
        <a href="#" [class.disabled]="isAnchorDisable" class="btn btn-primary"><span>Track Pace</span></a>
        <a href="#" [class.disabled]="isAnchorDisable" class="btn common-button"><span>{{'CP_LM_circuitDetailsPage_circuit' | customTranslation: translationData}}</span></a>
        <a href="#" [class.disabled]="isAnchorDisable" class="btn common-button"><span>{{this.circuitData.layouts.length}} {{'CP_LM_landingPage_tracks' | customTranslation: translationData}}</span></a>
      </div>
    </div>
    <div id="tourCoverContent" class="tour-cover-content">

      <div  *ngIf="this.circuitData?.media" class="annotations-image slideshow-wrapper">
        <ng-container *ngIf="this.circuitimageData">
          <img id="annotation-cover" [src]="this.circuitimageData | safe" class="fadeIn-items" alt="image"/>
        </ng-container>
        <ng-container *ngIf="!this.circuitimageData">
          <div class="spinner-border spinner">
          </div>
        </ng-container>
      </div>
      <div *ngIf="this.circuitData?.web || this.circuitData.address" class="circuit-description" id="annotation-description">
        <p *ngIf="!isReadMore" id="descriptions" class="fadeIn-items default-height">
          {{ this.circuitData?.description }}
        </p>
        <p *ngIf="isReadMore" id="description" class="fadeIn-items add-height">
          {{ this.circuitData?.description }}
        </p>
        <span *ngIf="!isReadMore &&(this.circuitData?.description?.length > 30 ) ">...</span>
        <span *ngIf="this.circuitData?.description?.length > 50" class="read-more text-decoration-underline" (click)="readMore()">
          {{ isReadMore ? 'Less': 'More' }}
        </span>
        <div class="overview-table">
          <div *ngIf="this.circuitData.web && this.circuitData.web.length>0" class="overview-table-row" >
            <div class="col-md-3">{{'CP_LM_circuitDetailsPage_website' | customTranslation: translationData}}</div>
            <div class="website-url">
              <a href={{this.circuitData.web}}>
                <span>{{this.circuitData.web}}</span>
              </a>
            </div>
          </div>
          <div *ngIf="this.circuitData.address" class="overview-table-row">
            <div class="col-md-3">{{'CP_LM_circuitDetailsPage_address' | customTranslation: translationData}}</div>
            <div>
              <u>{{this.circuitData.address}}</u>
            </div>
          </div>
        </div>
      </div>

    </div>    
    <div class="track-data" *ngIf="this.slides.length > 1">
      <div class="track-header">
        <h2 class="custom-overflow-width">{{'CP_LM_circuitDetailsPage_storiesheader' | customTranslation: translationData}}</h2>
        <div *ngIf="this.slides && this.slides.length > 3" class="nav-section">
          <span id="leftIcon" class="grey-circle" (click)="this.goRight()"> <span class="arrow-icn-left"></span> </span>
          <span id="rightIcon" class="grey-circle" (click)="this.goLeft()" > <span class="arrow-icon-right"></span> </span>
        </div>         
      </div>
      <div>
        <app-track-details (sendButtonOverlay)="disableArrowIcon($event)" [moveCarousel]="moveCarousel" (sendTrackData)="getemitTrackData($event)" [access_Token]="access_Token" [track]="this.slides"></app-track-details>
      </div>
    </div>
  </div>
  </div>
  </ng-container>
  <ng-container *ngIf="isPageNotFound">
    <app-no-route></app-no-route>
  </ng-container>

</ng-container>