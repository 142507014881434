<ng-container *ngIf="this.featureFlags.general.enableExplore">
<header id="main-header" [ngClass]="{'no-animation': noAnimation }"><app-header></app-header></header>
<main id="main-content"><router-outlet></router-outlet></main>
<div *ngIf="showToast">
    <app-toast [message]="toastMessage" [toastType]="toastType" [position]="position" [leftPosition]="leftPosition">
    </app-toast>
  </div>
<app-refresh-token></app-refresh-token>
<app-footer></app-footer>
</ng-container>
<ng-container *ngIf="!this.featureFlags.general.enableExplore">
<app-coming-soon></app-coming-soon>
</ng-container>
