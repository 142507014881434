<div id="modal-container" >
    <div class="modal open-modal" tabindex="-1" role="dialog">
        <div class="modal-dialog-box" role="document">
            <div class="modal-content" [ngClass]="type">
                <!--Popup Header -->
                <div *ngIf="isHeaderVisible" class="common-modal-header">
                    <div class="form-row sp-btw">
                    <div class="form-item">
                        <h3>{{headerTitle}}</h3>
                    </div>   
                    </div>
                </div>
                <!-- Popup content -->
                <div [ngClass]="component=='aliasComponent'?'w-auto modal-body-container':'modal-body-container'">
                    <ng-container [ngTemplateOutlet]="showModalContent"></ng-container>
                </div>
                <!-- Interactive Buttons -->
                <div class="popup-footer" *ngIf="isFooterVisible">
                    <div class="button-region right">
                    
                        <button *ngIf="isCloseButtonVisible" (click)="close()" class="secondary">
                            <span>{{closeButtonTitle}}</span>
                        </button>

                        <div >
                        <button  *ngIf="isSaveButtonVisible" class="enabled" (click)="save()" class="primary">
                            <span>{{saveButtonTitle}}</span>
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>