import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppServicesService } from 'src/app/services/app-services.service';
import { environment } from 'src/environments/environment';
import { Location } from "@angular/common";
import * as L from "leaflet";
import { Map, TileLayer } from 'leaflet';
import 'leaflet.motion/dist/leaflet.motion.js'

import { TranslationService } from 'src/app/services/translation.service';
import { LanguageService } from 'src/app/services/language.service';
import { TourMapService } from './tour-map.service';
import { ToastService, ToastType } from 'src/app/services/toast.service';
declare var createUnityInstance: any;
declare var TourMapConfiguration:any;
// declare let this.tourmapAPI: any;
@Component({
  selector: 'app-emotion-tour-summary',
  templateUrl: './emotion-tour-summary.component.html',
  styleUrls: ['./emotion-tour-summary.component.scss'],
})

export class EmotionTourSummaryComponent implements OnInit, OnChanges {
  @Input() public trackClusters: any;
  @Input() public clusterData: any;
  @Input() public loadAnnotations: any;
  @Input() public tourGuid: any;
  @Input() public initialize: any;
  @Input() public navFromLocation: any = true;
  @Input() public selectedCoord: any;

  @ViewChild("2DmapElement") el!: ElementRef<any>;

  @Output() public backClicked = new EventEmitter<any>();


  selectedIndex: any = [];
  @ViewChildren('scrollMe')
  elms!: QueryList<any>;
  mapHeight = 'calc(100vh - 100px)';
  mapWidth = 'calc(100vw - 820px)';
  detectedElms = [];
  apiKey = environment.apikey;
  trackGuid: any;
  destination: any;
  guidLength = 36;
  guid: any;
  counter: number = 0;
  selectedObject: any;
  tempObj: any = [];
  tourObj: any = [];
  indexList: any = [];
  currentIndex: number = 0;
  nextIndex: number = 0;
  trackLength: any;
  reverseRoutePath: any;
  previousSelectedValue: any;
  selectedAnnotationId: any;
  lastElement: boolean = false;
  public showHighLights: boolean = true;
  clusterList: any;
  public overallTourCluster: any = [];
  public forwardPathArray: any = null;
  public scrollPoints: any = 0;
  public containerRect?: any;
  public elementRect: any;
  public container: any;
  public lastScrollTop: any = 0;
  public reverseRoutePathArray: any = null;
  public copyOfForwardArray: any;
  public reversedArray: any = null;
  public routeDistance: any;
  public selectedCluster: any = [];
  public ulElementDetails: any;
  public coverImageContainer: any;
  public clusterDescription: any;
  public commonCoverImageContainer: any;
  public coverImageElement: any;
  public opacity: any;
  public fadeInElement: any;
  public listElements: any = [];
  public count = 1;
  public hasReachedScrollLimit: boolean = false;
  public scrollCount: any = 0;
  public routeArray: any = [0];
  public previousScrollPoint: any = null;
  public unitScroll: any;
  public calcPoint: any;
  public callScrollCount: any = 1;
  public previousScrollTop: any = 0;
  public previousRouteArray: any = [];
  public highlightRegionContainer: any;
  public tourContContainer: any;
  public isScrolledTitle: boolean = false;
  public totalArray: any = [];
  public reverseScrollCount = 1;
  public isDirection: string = 'forward';
  public reverseCount: any = null;
  public currentArray: any = [];
  public reverseCurrentArray: any = [];
  public reverseTotalPoints: any = [];
  public mainLayout: any;
  public access_Token: any;
  public fromId: any = '';
  public toId: any = '';
  public scrollPoint: any;
  public isShowTourDataMap: boolean = true;
  public tourDataMap: any;
  public tourInitiation: any = 50;
  public scrollIntialPosition: any;
  public backdropElement: any;
  public backdropTitle: any;
  public overAllTourTitle: any;
  public scrollBarPosition: any;
  public fadeOutValue: any;
  public fadeInValue: any;
  public dynamicSrollBar: any;
  public dynamicHeight: any;
  staticStickyHeight: any;
  staticStickyClass: any;
  containerSpec: any;
  listHeightValue: any;
  bookmarkIcon: any;
  listElementli: any;
  listElementTop: any;
  listElementBottom: any;
  distDomheight: any;
  isAnchorDisable: any = true;
  overview: any;
  isReadMore: boolean = true;
  sampleDesc = ``;
  tourCover: any;
  highlightRegionContainerBottom: any;
  showDetails: any = false;
  annotationDetails: any;
  absoluteRouteUrl: any;
  requestHeaders: any;
  showLanding: any = true;
  mapInstance: any;
  tileLayer: any;
  coordinate = [48, 9];
  showMapData: any = false;
  annotationsList: any;
  maxValue: any = 0;
  maxBottomValue: any = 0;
  translationData = [];
  language = '';
  
  tourmapAPI: any;
  unityInstance: any;
  setAccurateFade:boolean = true;
  enableSummaryButton:boolean = true;
  showShareSection:boolean = true;
  showHighlightsIndex:boolean = true;
  firstHalf: any;
  secondHalf: any;
  polylineDraw: any;
  innerWidth: any;
  innerHeight: any;


  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    window.location.reload();
    localStorage.removeItem('filter');

  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event: any) {
    // this.setMapCenterBasedOnScreen();
    this.isShowTourDataMap = false;
    this.detectElms();
    this.revealHighLightBackground();
    this.scrollPoints = event.target.scrollTop;
    this.calculateOpacity();
    this.applyClassToArrow();
    this.calculateFadeOutNew();
    this.calculateFadeInNew();
    this.holdDivInmobile();

    if (this.listElements[0]?.getBoundingClientRect().top <= (this.containerRect?.height / 2)) {
      this.measureScrollPercentage();
    }
    this.highlightRegionContainer = document.getElementById('list-element')?.getBoundingClientRect().top;
    this.highlightRegionContainerBottom = document.getElementById('parent-list')?.getBoundingClientRect().bottom;
    this.tourContContainer = document.getElementById('hightlight-content')?.getBoundingClientRect().top;
    let tourContTop = (this.tourContContainer / this.containerRect?.height) * 100;
    this.tourCover = document.getElementById('hightlight-content');

    if(this.innerHeight <= 750){
      if (tourContTop < 56){
        this.tourCover.classList.add('arrow-scrolled');        
      } else {
        this.tourCover.classList.remove('arrow-scrolled');
      }
    } 

    if(this.innerHeight > 750){
      if (tourContTop < 65){
        this.tourCover.classList.add('arrow-scrolled');        
      } else {
        this.tourCover.classList.remove('arrow-scrolled');
      }
    } 

    if (tourContTop > 66 && this.innerWidth > 768) {
      this.tourmapAPI.setAnnotationScrollValue('', this.trackClusters[0]?.id, 0);
    }

    if ((this.highlightRegionContainer / this.containerRect?.height) * 100 < 20) {
      this.isScrolledTitle = true;
      this.backdropTitle.setAttribute('style', 'opacity:1;');
      this.bookmarkIcon.setAttribute('style', 'opacity:1;')

    } else {
      this.isScrolledTitle = false;
      this.backdropTitle.setAttribute('style', 'opacity:0;');
    }
  }
  calculateOpacity() {
    for (let i = 0; i < this.fadeInElement?.length; i++) {
      let a =
        (this.fadeInElement[i]?.getBoundingClientRect().top /
          this.containerRect?.height) *
        100;
      if (a <= 90) {
        this.opacity = 100;

        if (this.fadeInElement[i]) {
          this.fadeInElement[i].setAttribute('style', 'opacity:100%');
        }
      } else if (a >= 90 && a < 99) {
        this.opacity = (90 - a) * 10;
    }
    }
  }

  setMapCenterBasedOnScreen(topValue?:any){
    if(this.innerWidth > 768){
      this.tourmapAPI.setMapCenter(0.69, 0.5);
    } else if(topValue){
      if(topValue > 0 && topValue < 50){ this.tourmapAPI.setMapCenter(0.5, 0); }
          if(topValue > 50 && topValue < 69){ this.tourmapAPI.setMapCenter(0.5, 0.1); }
          if(topValue > 69 && topValue < 113){ this.tourmapAPI.setMapCenter(0.5, 0.2); }
          if(topValue > 113 && topValue < 120){ this.tourmapAPI.setMapCenter(0.5, 0.3); }
          if(topValue > 120 && topValue < 150){ this.tourmapAPI.setMapCenter(0.5, 0.5); }
    } else{
      this.tourmapAPI.setMapCenter(0.5, 0.5);
    }
  }

  downloadTrackDetails() {
    let guid = this.trackGuid ? this.trackGuid : this.tourGuid;
    this.appservice
      .downloadTrackDetails(guid, this.access_Token)
      .subscribe((data: any) => {
        const blob = new Blob([data], {
          type: "application/zip",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.download = this.overview.properties.name;
        anchor.href = url;
        anchor.click();
      });
  }

  

  applyClassToArrow() {
    this.tourCover = document.getElementById('hightlight-content');
    this.tourCover.classList.add('arrow-scrolled');
  }

  calculateFadeOutNew() {
    this.distDomheight = document.getElementsByClassName('distance-between');
    this.listElements = document.getElementsByClassName('sticky-content');
    this.listElementBottom = document.getElementsByClassName('li-bottom');
    if(this.innerWidth > 768){
      for (let i = 0; i < this.listElements.length; i++) {

        this.fadeOutValue = (this.listElementBottom[i]?.getBoundingClientRect().bottom / this.containerRect?.height) * 100;
        this.fadeOutValue = Math.round(this.fadeOutValue);
  
        if (this.fadeOutValue <= 90 && this.fadeOutValue > 50) {
          let sv = (90 - this.fadeOutValue) * 1;
          let Ou = sv * 1;
          let Fo = (100 - (Ou * 2.5678));
          let Ov = (100 - Ou);
          let Ty = Ou * 0.66;
          let Sc = Ov * 1;
          let blp = Ou / 8;
  
  
          if (this.fadeOutValue < 90 && this.fadeOutValue >= 70) {
            this.listElements[i].setAttribute('style', 'opacity:' + Fo * 0.90 + '%; transform: translateY(' + Ty + '%) scale3d(0.' + Sc + ', 0.' + Sc + ', 0.' + Sc + '); filter: blur(' + blp + 'px); transform-style: preserve3d;');
          }
          else if (this.fadeOutValue < 70 && this.fadeOutValue >= 50) {
            this.listElements[i].setAttribute('style', 'opacity:' + Fo * 0.80 + '%; transform: translateY(' + Ty + '%) scale3d(0.80, 0.80, 0.80); filter: blur(' + blp + 'px); transform-style: preserve3d;');
          }
  
        }
        else if (this.fadeOutValue >= 90) {
          this.listElements[i].setAttribute('style', 'opacity: 100%;');
        }
        else if (this.fadeOutValue < 50) {
          this.listElements[i].setAttribute('style', 'opacity: 0; transform: translateY(15%) scale3d(0.80, 0.80, 0.80); filter: blur(4px); transform-style: preserve3d;');
        }
      }
    }
    
  }


  calculateFadeInNew() {
    this.listElementli = document.getElementsByClassName('fade-in-li-content');
    this.listElementTop = document.getElementsByClassName('li-top');
    for (let i = 0; i < this.listElements.length; i++) {

      this.fadeInValue = (this.listElementTop[i]?.getBoundingClientRect().bottom / this.containerRect?.height) * 100;
      this.fadeInValue = Math.round(this.fadeInValue);

      if (this.fadeInValue < 90 && (i === 0) && this.fadeInValue > 15) {

        this.scrollPoint = 0;
        this.createArrayToSendMap('route_view', 0, i)
      }
      if (this.fadeInValue <= 90 && i > 0) {

        this.scrollPoint = 0;

        if (this.isDirection.toLowerCase() == 'forward') {
          let fromIndex
          let toIndex
          if (this.selectedIndex[0] + 1 < 0) {
            fromIndex = this.selectedIndex[0];
            toIndex = this.selectedIndex[0];
          } else if (this.selectedIndex[0] < 0) {
            fromIndex = this.selectedIndex[0] + 1;
            toIndex = this.selectedIndex[0] + 1;
          } else if (this.selectedIndex[0] >= this.trackClusters.length) {
            fromIndex = this.selectedIndex[0];
            toIndex = "";
          }
          else {
            fromIndex = this.selectedIndex[0];
            toIndex = this.selectedIndex[0] + 1;
          }
          // }          
          this.createArrayToSendMap(fromIndex, toIndex, 'forward')
        }
        if (this.isDirection.toLowerCase() == 'reverse') {
          let fromIndex
          let toIndex
          if (this.selectedIndex[0] + 1 < 0) {
            fromIndex = this.selectedIndex[0];
            toIndex = this.selectedIndex[0];
          } else if (this.selectedIndex[0] < 0) {
            fromIndex = this.selectedIndex[0] + 1;
            toIndex = this.selectedIndex[0] + 1;
          } else if (this.selectedIndex[0] >= this.trackClusters.length) {
            fromIndex = this.selectedIndex[0];
            toIndex = "";
          }
          else {
            fromIndex = this.selectedIndex[0];
            toIndex = this.selectedIndex[0] + 1;
          }
          this.createArrayToSendMap(fromIndex, toIndex, 'reverse')
        }
      }

      if(this.innerWidth > 768){
      if (this.fadeInValue < 100 && this.fadeInValue >= 80) {
        let fisv = (100 - this.fadeInValue) * 1;
        let fiOu = fisv * 0.8;
        let fiscv = ((80 + fisv) / 100) * 0.98;
        let fiblv = (20 - (fisv)) / 8;

        this.listElementli[i].setAttribute('style', 'opacity: ' + fiOu * 5 + '%; transform: translateY(-' + fisv / 20 + '%) scale3d(' + fiscv + ', ' + fiscv + ', ' + fiscv + '); filter: blur(' + fiblv + 'px); transform-style: preserve-3d;');

      }
      else if (this.fadeInValue > 101) {
        this.listElementli[i].setAttribute('style', 'opacity: 0%; transform: translateY(-1%) scale3d(1, 1, 1); filter: blur(0); transform-style: preserve-3d;');
      }

      else if (this.fadeInValue >= 100) {
        this.listElementli[i].setAttribute('style', 'opacity: 0%; transform: translateY(-1%) scale3d(0.8, 0.8, 0.8); filter: blur(4px); transform-style: preserve-3d;');
      }
      else if (this.fadeInValue < 80) {
        this.listElementli[i].setAttribute('style', 'opacity: 100%; transform: translateY(0) scale3d(1, 1, 1); filter: blur(0); transform-style: preserve-3d;');
      }
    }
    }
  }


  calculateRouteArray(element: any) {
    if (this.previousScrollPoint !== element || this.reverseCount !== element) {
      if (this.isDirection.toLowerCase() === 'forward') {
        this.callScrollCount = 1;
      } else if (this.isDirection.toLowerCase() === 'reverse') {
        this.reverseScrollCount = 1;
      }
    }
    if (this.callScrollCount || this.reverseScrollCount) {
      this.trackClusters?.filter((obj: any, index: any) => {
        if (element === index) {
          this.tourObj?.geometry.coordinates.map((val: any, i: any) => {
            if (
              obj.properties.projection_on_tour.coordinates[0] === val[0] &&
              obj.properties.projection_on_tour.coordinates[1] === val[1] &&
              this.count
            ) {
              if (this.isDirection.toLowerCase() === 'forward') {
                this.fromId = this.toId;
                this.toId = obj.id;
                this.scrollPoint = 0;
                this.previousScrollPoint = element;
                this.callScrollCount = 0;
                if (element !== this.trackClusters.length - 1) {
                  this.routeArray = this.tourObj?.geometry.coordinates.slice(
                    this.routeArray.length,
                    i + 1
                  );
                } else {
                  this.routeArray = this.tourObj?.geometry.coordinates.slice(
                    this.routeArray.length,
                    this.tourObj.geometry.coordinates.length - 1
                  );
                }
                this.currentArray = this.routeArray;

                this.reverseTotalPoints = this.reverseTotalPoints.concat(
                  this.routeArray
                );
                this.reverseTotalPoints = [...new Set(this.reverseTotalPoints)];
              } else if (this.isDirection.toLowerCase() === 'reverse') {
                this.fromId = this.toId;
                this.toId = obj.id;
                this.reverseCount = element;
                this.reverseScrollCount = 0;

                if (element == this.trackClusters.length + 1) {
                  this.routeArray = this.tourObj?.geometry.coordinates.slice(
                    this.tourObj?.geometry.coordinates.length,
                    i + 1
                  );
                } else {
                  this.routeArray = this.tourObj?.geometry.coordinates.slice(
                    this.routeArray.length,
                    i + 1
                  );
                }

                this.totalArray = this.totalArray.concat(this.routeArray);
                this.totalArray = [...new Set(this.totalArray)];

                this.reverseCurrentArray = this.routeArray;
              }
            }
          });
        }
      });
    }
  }

  mouseup() {
    this.setMapCenterBasedOnScreen();
    this.calculateOpacity();
  }
  detectElms() {
    this.count = 1;
    const detectedElms: any = [];
    const listEle: any = [];
    this.elms.forEach((elm, index) => {
      if (this.isInViewport(elm.nativeElement) && elm.nativeElement.id != 'parent-list') {
        detectedElms.push(index - 1);

        listEle.push(elm.nativeElement);

        this.lastElement = false;
      }
    });
    this.selectedIndex = detectedElms;
    console.log('elms detected', this.selectedIndex)


    this.listElements = listEle;
    let st = this.scrollPoints;
    if (st >= this.lastScrollTop) {
      this.isDirection = 'forward';


    } else {
      this.isDirection = 'reverse';

    }
    this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  }

  constructor(
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private appservice: AppServicesService,
    private router: Router,
    private location: Location,
    private translationService: TranslationService,
    private languageService: LanguageService,
    private mapService: TourMapService,
    private toastService: ToastService
  ) {
    this.renderer.listen('window', 'resize', this.detectElms.bind(this));
    this.renderer.listen('window', 'resize', (evt) => {
      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
      this.setMapCenterBasedOnScreen();
    });

    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.renderer.listen('window', 'scroll', this.detectElms.bind(this));
    this.tourmapAPI = this.mapService.returnMapVariable();
    console.log(this.tourmapAPI , 'this.tourmapAPI ')
  }
  ngOnInit() { 
    const featureFlags = this.translationService.featureFlags;
    this.enableSummaryButton = featureFlags.tourDetails.enableSummaryButton
    this.setAccurateFade = featureFlags.tourDetails.setAccurateFade
    this.showShareSection = featureFlags.tourDetails.showShareSection
    this.showHighlightsIndex = featureFlags.tourDetails.showHighlightsIndex
    this.language = this.languageService.getLanguage();
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.appservice.dummyToken().subscribe({
      next: (data: any) => {
        if (data['access_token']) {
          this.access_Token = data.access_token;

          this.requestHeaders = {
            authorization: 'Bearer ' + this.access_Token,
            'content-type': 'application/json',
            caller: '3d_module',
            'user-role':'Customer_Portal'
          };
        }
      },
    });
    if (this.trackClusters?.length > 0) {
      this.trackClusters.forEach((element:any, index:any) => {
        element.iteration = index + 1
      });
      this.firstHalf = this.trackClusters.slice(0,(this.trackClusters?.length/2))
      this.secondHalf = this.trackClusters.slice((this.trackClusters?.length/2), this.trackClusters?.length)

      for (let i = 0; i <= (this.trackClusters.length - 2); i++) {
        let distanceBetween = this.trackClusters[i + 1].properties.route_position - this.trackClusters[i].properties.route_position
        this.trackClusters[i].properties.distanceBetween = distanceBetween
      }

      for (let i = 0; i <= (this.trackClusters.length - 1); i++) {
        this.trackClusters[i].properties.distance_from_origin = (this.trackClusters[i].properties.distance_from_origin / 1000).toFixed(2)
      }

      for (let i = 0; i <= (this.trackClusters.length - 2); i++) {
        let distanceBetween = this.trackClusters[i + 1].properties.distance_from_previous_cluster
        this.trackClusters[i].properties.travelDistance = distanceBetween
      }

    }
    this.clusterList = this.trackClusters?.map((item: any) => {
      if (item.properties.cluster_name) {
        return item.properties.cluster_name;
      }
    });
    this.overallTourCluster = this.trackClusters ? this.trackClusters[0] : null;
    this.route.paramMap.subscribe((paramMap: any) => {
      const nameParam = paramMap.get('name');
      if (nameParam && nameParam !== 'login') {
        this.destination = nameParam.substring(
          0,
          nameParam.length - this.guidLength - 1
        );
        this.guid = nameParam.slice(nameParam.length - this.guidLength);
        this.trackGuid = this.guid;

      }
    });
    this.loadUnity();

    this.apiKey = environment.apikey;
  }

  initializeData() {
    if (this.trackClusters?.length > 0) {
      this.trackClusters.forEach((element:any, index:any) => {
        element.iteration = index + 1
      });
      this.firstHalf = this.trackClusters.slice(0,(this.trackClusters?.length/2))
      this.secondHalf = this.trackClusters.slice((this.trackClusters?.length/2), this.trackClusters?.length)
      for (let i = 0; i <= (this.trackClusters.length - 2); i++) {
        let distanceBetween = this.trackClusters[i + 1].properties.route_position - this.trackClusters[i].properties.route_position
        this.trackClusters[i].properties.distanceBetween = distanceBetween
      }

      for (let i = 0; i <= (this.trackClusters.length - 1); i++) {
        this.trackClusters[i].properties.distance_from_origin = (this.trackClusters[i].properties.distance_from_origin / 1000).toFixed(2)
      }

      this.trackClusters = this.trackClusters.sort(
        (first: any, second: any) =>
          first.properties.distance_from_origin - second.properties.distance_from_origin
      );
    }    
    this.clusterList = this.trackClusters?.map((item: any) => {
      if (item.properties.cluster_name) {
        return item.properties.cluster_name;
      }
    });
    this.overallTourCluster = this.trackClusters ? this.trackClusters[0] : null;
    this.route.paramMap.subscribe((paramMap: any) => {
      const nameParam = paramMap.get('name');
      if (nameParam && nameParam !== 'login') {
        this.destination = nameParam.substring(
          0,
          nameParam.length - this.guidLength - 1
        ); // -1 is added to remove the "-" at the end of track name
        this.guid = nameParam.slice(nameParam.length - this.guidLength);
        this.trackGuid = this.guid;
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['loadAnnotations']?.currentValue) {
      this.loadAnnotation();
    }
    if (changes['initialize']?.currentValue) {
      this.setUpLeaflet();
      this.initializeData();
      this.initializeScreenValues();

    }
  }
  ngAfterContentInit() {
    this.distDomheight = document.getElementsByClassName('distance-between');
    this.overview = this.clusterData?.features[0];
    if (this.overview) {
      let data = new Date(this.overview?.properties.createdDate)
      const month = data.toLocaleString('default', { month: 'long' });
      this.overview.date = month + " " + data.getDate() + " " + data.getFullYear();
      let lengthOfTour = this.overview.properties.trackLength
      if((lengthOfTour / 1000) < 1){
        this.overview.lengthOfTour = (lengthOfTour) + ' m'
      } else{
        this.overview.lengthOfTour = Math.floor((this.overview.properties.trackLength / 1000)) + ' km'
      }
    }



    this.tourObj = this.clusterData?.features[0];
    this.selectedObject = this.tourObj?.geometry.coordinates.slice(
      this.currentIndex,
      this.nextIndex
    );
    this.trackLength = this.tourObj?.properties.trackLength / 1000;
    this.trackClusters = this.trackClusters?.map((item: any) => ({
      ...item,
      isReadMore: false,
    }));
    this.detectElms.bind(this);
    this.container = document.getElementById('highlight-region');
    if (this.container) {
      this.containerRect = this.container?.getBoundingClientRect();
    }
    this.coverImageElement = document.getElementById('annotation-card');
    this.fadeInElement = document.getElementsByClassName('fadeIn-items');

    this.calculateRouteArray(0);
    this.calculateOpacity();
    if (this.trackClusters?.length > 1) {
      this.fromId = this.overallTourCluster?.id;
      this.toId = this.trackClusters[0]?.id;
    }



  }

  initializeScreenValues() {
    this.distDomheight = document.getElementsByClassName('distance-between');
    this.overview = this.clusterData?.features[0];
    if (this.overview) {
      let data = new Date(this.overview?.properties.createdDate)
      const month = data.toLocaleString('default', { month: 'long' });
      this.overview.date = month + " " + data.getDate() + " " + data.getFullYear();
      let lengthOfTour = this.overview.properties.trackLength
      if((lengthOfTour / 1000) < 1){
        this.overview.lengthOfTour = (lengthOfTour) + ' m'
      } else{
        this.overview.lengthOfTour = Math.floor((this.overview.properties.trackLength / 1000)) + ' km'
      }

    }

    this.tourObj = this.clusterData?.features[0];
    this.selectedObject = this.tourObj?.geometry.coordinates.slice(
      this.currentIndex,
      this.nextIndex
    );
    this.trackLength = this.tourObj?.properties.trackLength / 1000;
    this.trackClusters = this.trackClusters?.map((item: any) => ({
      ...item,
      isReadMore: false,
    }));
    this.detectElms.bind(this);
    this.container = document.getElementById('highlight-region');
    if (this.container) {
      this.containerRect = this.container?.getBoundingClientRect();
    }
    this.coverImageElement = document.getElementById('annotation-card');
    this.fadeInElement = document.getElementsByClassName('fadeIn-items');

    this.calculateRouteArray(0);
    this.calculateOpacity();

    this.fromId = this.overallTourCluster.id;
    this.toId = this.trackClusters[0].id;


  }

  setUpLeaflet() {
    this.mapInstance = new Map(this.el.nativeElement, {
      zoomControl: true,
      zoom: 4,
      worldCopyJump: true,
      center: [this.coordinate[0], this.coordinate[1]],
      maxBounds: new L.LatLngBounds([
        [-90, -180],
        [90, 180],
      ]),
    });

    this.mapInstance.zoomControl.setPosition("bottomright");

    // create + add tile layer to map
    this.tileLayer = new TileLayer(
      'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
      {
        maxZoom: 19,
        minZoom: 2,
        attribution: "",
      }
    ).addTo(this.mapInstance);

    this.plotSelectedTour(this.selectedCoord);
    console.log('pixel north-east', document.getElementById('unity-canvas')?.offsetWidth, 0)
    console.log('pixel south-west', 0, document.getElementById('unity-canvas')?.offsetHeight)
  }

  plotSelectedTour(coordinates: any) {
    let polylineData = coordinates
    this.polylineDraw = L.polyline(polylineData, { color: '#344e94' }).addTo(this.mapInstance);
    this.mapInstance.fitBounds(this.polylineDraw.getBounds(), {
      paddingTopLeft: [200, 100],
      paddingBottomRight: [100, 300],
    });
    setTimeout(() => {  // adding delay to get correct bounding box
      this.alignTest(); // Function for calling accurate fade
      if (this.setAccurateFade) {
        this.tourmapAPI.onMapLoaded = function () {
          console.log('overridden load function')
          let mapElement = document.getElementById("twodmap");
          if (mapElement) {
            mapElement.classList.add('animate-fade')
          }
        }
      } else {
        setTimeout(() => {  // Feature toggle for enabling hardcoded fadeout time in map added
          this.showMapData = true;
        }, 15000)
      }  
    }, 3000)   

    
  }

  alignTest() {
    const canvas = document.getElementById("twodmap");
    if(canvas){
      this.tourmapAPI.setAlignedView(
        {lon: this.polylineDraw.getBounds()._northEast.lng, lat: this.polylineDraw.getBounds()._northEast.lat},
        {x: this.mapInstance.latLngToLayerPoint(this.polylineDraw.getBounds()._northEast).x, y: this.mapInstance.latLngToLayerPoint(this.polylineDraw.getBounds()._northEast).y},
        {lon: this.polylineDraw.getBounds()._southWest.lng, lat: this.polylineDraw.getBounds()._southWest.lat},
        {x: this.mapInstance.latLngToLayerPoint(this.polylineDraw.getBounds()._southWest).x, y: this.mapInstance.latLngToLayerPoint(this.polylineDraw.getBounds()._southWest).y}
    );
    }
    
}

copyTourURL(){
  window.navigator.clipboard.writeText(window.location.href);
  this.toastService.show(ToastType.Success, 'Link copied to clipboard!', '8%', '40%');
        setTimeout(() => {
          this.toastService.hide();
        }, 4000);
}

  goToWebsite(url: any) {
    window.open(url, '_blank');
  }

  showTour(event: any) {
    this.showLanding = false;
  }

  goToExploreMain(){
    this.goToTopOfTour();
    let mapElement = document.getElementById("twodmap");
          if (mapElement) {
            mapElement.classList.remove('animate-fade')
          }
    setTimeout(() => {  // Feature toggle for enabling hardcoded fadeout time in map added
      this.navigateBack();
    }, 200)
  }

  navigateBack() {
      this.location.go('/'+this.language+'/explore');        
      this.showMapData = false;  

      if(!this.navFromLocation){
        this.backClicked.emit();
        this.mapInstance.remove();
      } else{
        localStorage.removeItem('filter');
        this.location.historyGo(0);
      }
  }

  goToTopOfTour(){
    var el = document.getElementById("highlight-region"); 
    // To set the scroll
    el?.scrollTo(0, 0);
  }

  getMaxValue(value: any) {
    if (value > this.maxValue) {
      this.maxValue = value;
    }
    return this.maxValue
  }

  getMaxBottomValue(value: any) {
    if (value > this.maxBottomValue) {
      this.maxBottomValue = value;
    }
    return this.maxBottomValue
  }

  holdDivInmobile(){
    for (let i = 0; i < this.listElements.length; i++) {
      let topValue = (this.listElementTop[i]?.getBoundingClientRect().top / this.containerRect?.height) * 100;
      console.log('topValue', i, topValue)
      if((i>0) && this.innerWidth <= 768 ){
        if(topValue < 130 && topValue > 50){
          this.setMapCenterBasedOnScreen(topValue)
        }
        if((topValue < 113 && topValue > 70)){
          let fadeItems = document.getElementsByClassName('fadeOut-items')
          fadeItems[i].setAttribute('style', 'display:initial; ');  
          let listElements = document.getElementsByClassName('sticky-content');
          listElements[i].setAttribute('style', 'position: sticky; top:82%; ');  
          let fromIndex = this.trackClusters[i]?.id;
          let toIndex = this.trackClusters[i+1]?.id 
          let scrollPoint = this.scaleToRange(topValue, 113, 69, 0, 1)
          this.tourmapAPI.setAnnotationScrollValue(
            fromIndex,
            toIndex,
            scrollPoint
          );          
        } else{
          let fadeItems = document.getElementsByClassName('fadeOut-items')
          fadeItems[i].setAttribute('style', 'display:block; ');
        }
        
      }
    }
   
  }

  createArrayToSendMap(fromIndex: any, toIndex: any, position: any) {
    if (this.trackClusters.length > 0) {
      this.fromId = fromIndex === 'route_view' ? fromIndex : this.trackClusters[fromIndex]?.id;
      this.toId = this.trackClusters[toIndex]?.id ? this.trackClusters[toIndex]?.id:'route_view';


      let contHeight = ((this.containerRect?.bottom - this.distDomheight[toIndex].getBoundingClientRect().bottom) / this.containerRect?.height) * 100;
      if (this.trackClusters.length === 1) {
        if (contHeight > 0 && contHeight <= 350) {
          this.scrollPoint = this.scaleToRange(contHeight, 0, 350, 0, 1)
        } else if (contHeight > 350) {
          this.scrollPoint = 1;
        } else if (contHeight < 0) {
          this.toId = this.fromId;
          this.scrollPoint = this.scaleToRange(contHeight, -400, 0, 0, 1)

        }
      } else {
        if (position === 0) {
          // let maxCont = ((this.containerRect?.bottom + 30) / this.containerRect?.height) * 100

          // let initialVal = this.getMaxValue(this.distDomheight[toIndex].getBoundingClientRect().bottom);
          // let minVal = ((this.containerRect?.bottom - initialVal) / this.containerRect?.height) * 100;

          // this.scrollPoint = this.scaleToRange(contHeight, minVal, maxCont, 0, 1)
          // if (contHeight > maxCont) {
          //   this.scrollPoint = 1;
          // }
          // if (contHeight < minVal) {
          //   this.scrollPoint = 0;
          // }
        }
        else 
        if(this.toId  === 'route_view' && this.fromId !== 'route_view'){
          let maxCont = (((this.containerRect?.bottom)) / this.containerRect?.height) * 100
          if(contHeight > maxCont){
            this.scrollPoint = 1;
          } else{
            this.scrollPoint = this.scaleToRange(contHeight, 0, maxCont, 0, 1)
          }
        }
         else if (contHeight > 0 && contHeight <= 203) {
          this.maxBottomValue = 0;
          this.scrollPoint = this.scaleToRange(contHeight, 0, 203, 0, 1)
        } else if (contHeight > 203) {
          this.scrollPoint = 1;

        } else if (contHeight < 0 && position != 0) {
          let initialVal = this.getMaxBottomValue(this.distDomheight[toIndex].getBoundingClientRect().bottom);
          let minVal = ((this.containerRect?.bottom - initialVal) / this.containerRect?.height) * 100;

          this.toId = this.fromId;
          this.scrollPoint = this.scaleToRange(contHeight, minVal, 0, 0, 1)

        }
      }
      if(this.innerWidth > 768) {
        this.sendAnnotationData();
      
      }
    }

  }

  emitAnnotationArrayId(data: any) {
    this.fromId = data[0].id;
    this.toId = data[1].id
  }

  isInViewport(element: any) {
    this.elementRect = element.getBoundingClientRect();
    if (
      this.elementRect.top <= this.containerRect?.top
        ? this.containerRect?.top - this.elementRect.top <=
        this.elementRect.height
        : this.elementRect.bottom - this.containerRect?.bottom <=
        this.elementRect.height
    ) {
      if (
        Math.floor(this.elementRect.bottom) >= 700 &&
        Math.floor(this.elementRect.bottom) <= 800
      ) {
      }
      return true;
    }
    return false;
  }
  async loadUnity() {
    const buildUrl = 'assets/unity/Build';
    await this.tourmapAPI.initialize(document.querySelector("#unity-canvas"), buildUrl).then(() => {
      // set route      
        this.requestHeaders = {
          authorization: 'Bearer ' + this.access_Token,
          'content-type': 'application/json',
          caller: '3d_module',
          'user-role':'Customer_Portal',
        };
        if (this.navFromLocation) {
          this.absoluteRouteUrl = `${environment.apiEndPoint}/v1/annotation/geojson?guid=${this.trackGuid}`;
          this.tourmapAPI.setRouteUrl(this.absoluteRouteUrl, this.requestHeaders);
          this.tourmapAPI.setAnnotationScrollValue('globe_view', 'globe_view', 0);

          // setTimeout(() => {  
          // this.tourmapAPI.setAnnotationScrollValue('route_view', 'route_view', 0);
          // // this.tourmapAPI.setAnnotationScrollValue('', this.trackClusters[0]?.id, 0);
          // // Feature toggle for enabling hardcoded fadeout time in map added
          // }, 5000)
          setTimeout(() => {  
            // this.tourmapAPI.setAnnotationScrollValue('route_view', 'route_view', 0);
            this.tourmapAPI.setAnnotationScrollValue('', this.trackClusters[0]?.id, 0);
            // Feature toggle for enabling hardcoded fadeout time in map added
            }, 9000)
          // this.tourmapAPI.onMapLoaded = function () {
          //   console.log('overridden load function')
          //   this.tourmapAPI.setAnnotationScrollValue('', this.trackClusters[0]?.id, 0);
          // }
          this.setMapCenterBasedOnScreen();
        }
    }).catch((message:any) => {
      alert(message);
    });

  }

  loadAnnotation() {
    if (this.tourmapAPI) {
      this.absoluteRouteUrl = `${environment.apiEndPoint}/v1/annotation/geojson?guid=${this.tourGuid}`;
      this.tourmapAPI.setRouteUrl(this.absoluteRouteUrl, this.requestHeaders);
      this.tourmapAPI.setAnnotationScrollValue('route_view', 'route_view', 0);
      this.setMapCenterBasedOnScreen();
    }

  }

  sendAnnotationData() {
    console.log('scrolll being sent',
      this.scrollPoint);
    this.tourmapAPI.setAnnotationScrollValue(
      this.fromId,
      this.toId,
      this.scrollPoint
    );
  }

  goToAnnotation(id: any) {
    if(this.enableSummaryButton){
      this.annotationDetails = null
    this.appservice
      .getAnnotationDetails(this.access_Token, id)
      .subscribe({
        next: (data: any) => {
          console.log('details', data)
          this.annotationDetails = data
          this.annotationDetails.properties.feature_type = this.annotationDetails.properties.feature_type.replace(/([A-Z])/g, ' $1')
          this.annotationDetails.properties.feature_type = this.annotationDetails.properties.feature_type.replace(/^./, (str: any) => { return str.toUpperCase() })
          this.annotationDetails.properties.route_position = Math.floor((this.annotationDetails.properties.route_position / 1000))
          this.showDetails = true;
        },
      });

    this.showDetails = true;
    this.tourmapAPI.setAnnotationScrollValue(
      id,
      id,
      0
    );
    }
    
  }

  goToDetailsPage() {
    this.showDetails = true;
  }

  closeDetailsPage(event: any) {
    this.showDetails = false;

  }

  scaleToRange(number: number, inMin: number, inMax: number, outMin: number, outMax: number) {
    return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  }

  setHeightStyle(data: any) {
    data = data * 4;
    let style = {
      'height': data + 'em'
    }
    return style;
  }

  measureScrollPercentage() {
    this.dynamicSrollBar = document.getElementsByClassName('progress-line');
    this.dynamicHeight = document.getElementsByClassName('dynamic-h');
    this.staticStickyClass = document.getElementsByClassName('fade-in-li-content');
    this.listElementli = document.getElementsByClassName('fadeOut-items');
    this.distDomheight = document.getElementsByClassName('distance-between');





    for (let i = 0; i < this.dynamicSrollBar.length; i++) {
      let n = (this.staticStickyClass[i]?.getBoundingClientRect().bottom / this.containerRect?.height)
      if (n < 0.94) {
        let o = 0.94 - n;
        o = o * 60;
      }
      let stickyheight = this.staticStickyClass[i]?.getBoundingClientRect().height;
      let contHeight = ((this.containerRect?.bottom - this.distDomheight[i].getBoundingClientRect().bottom) / this.containerRect?.height) * 100;
      let progressPerc = contHeight * 1;
      if(i === 0 && progressPerc < 104 && progressPerc > 40){
        if(this.innerWidth > 768){
          this.tourmapAPI.setAnnotationScrollValue('', this.trackClusters[0]?.id, progressPerc * 0.01);
        }
      }
      // if(this.innerWidth > 768){
        // this.dynamicHeight[i].setAttribute('style', 'height:' + stickyheight * 0.5 + 'px;');
      // }
      this.dynamicSrollBar[i].setAttribute('style', 'width:' + progressPerc * 1 + '%;');
      


    }

  }
  revealHighLightBackground() {
    this.tourDataMap = document.getElementById('tour-data-main');
    this.backdropElement = document.getElementById('bg-highlight');
    this.backdropTitle = document.getElementById('title-bg-sticky');
    this.bookmarkIcon = document.getElementById('bookmark');
    if (this.tourDataMap) {
      this.tourDataMap = this.tourDataMap.getBoundingClientRect();
    }
    if (this.tourDataMap.top / this.containerRect?.height >= 0.5) {
      this.scrollIntialPosition = 0;
      this.backdropElement.setAttribute('style', 'opacity:0; backdrop-filter: blur(0);');
      this.bookmarkIcon.setAttribute('style', 'opacity:0;');
      this.backdropTitle.setAttribute('style', 'opacity:0;');
    } else if (
      this.tourDataMap.top / this.containerRect?.height <= 0.5 &&
      this.tourDataMap.top / this.containerRect?.height >= 0.3
    ) {
      this.scrollIntialPosition =
        (0.5 - this.tourDataMap.top / this.containerRect?.height) * 5;
      this.backdropElement.setAttribute('style', 'opacity:' +
        this.scrollIntialPosition +
        '; backdrop-filter: blur(0);');
      this.bookmarkIcon.setAttribute('style', 'opacity:' +
        this.scrollIntialPosition + '; ');
      this.backdropTitle.setAttribute('style', 'opacity:0;');

    } else if (this.tourDataMap.top / this.containerRect?.height <= 0.3) {
      this.scrollIntialPosition = 1;
      this.backdropElement.setAttribute('style', 'opacity:1; backdrop-filter: blur(0.5em);');
      this.bookmarkIcon.setAttribute('style', 'opacity:1;');
      this.backdropTitle.setAttribute('style', 'opacity:0;');
    }
    else if (this.tourDataMap.top / this.containerRect?.height <= 0.2) {
      this.scrollIntialPosition = 1;
      if(this.innerWidth < 768){
        this.backdropTitle.setAttribute('style', 'opacity:0;');
      } else {
        this.backdropTitle.setAttribute('style', 'opacity:1;');
      }
    }
  }
  getOverAllTourTitleTop() {
    this.overAllTourTitle = document.getElementById('tour-title')?.getBoundingClientRect();
    this.overAllTourTitle = this.overAllTourTitle.top;
    if ((this.overAllTourTitle / this.containerRect?.height) <= 1) {
      if(this.innerWidth < 768){
        this.backdropTitle.setAttribute('style', 'opacity:0;');
      } else {
        this.backdropTitle.setAttribute('style', 'opacity:1;');
      }
    }
    else {
      this.backdropTitle?.setAttribute('style', 'opacity:0;');
    }
  }
}