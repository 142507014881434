import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { Location } from "@angular/common";
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/services/language.service';
import { ToastService, ToastType } from 'src/app/services/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public userName: any;
  public password: any;
  signedIn: boolean = false;
  translationData = [];
  language = 'en-GB';
  
  constructor(private router: Router, 
    private awsAuth: AwsAuthService, 
    private location: Location, 
    private translationService: TranslationService, private languageService: LanguageService, private toastService: ToastService,) {}

  ngOnInit(): void {


    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )

    const selectedLang = this.languageService.getLanguage();
    if (selectedLang) {
      this.language = selectedLang;
    } else {
      this.language = environment.supported_language[0];
      localStorage.setItem('language', this.language);
    }
    
    this.authorizeCode();    
  }

  login() {
    if (
      this.userName.toLowerCase() == 'admin' &&
      this.password == 'XxPX8MSgrgh7gsv2'
    ) {
      this.initiateNavigation();
    } else{
      this.toastService.show(ToastType.Error, 'Incorrect credentials ', '8%', '40%');
      setTimeout(() => {
        this.toastService.hide();
      }, 4000);
    }
  }

  initiateNavigation(){
      let url = localStorage.getItem('routeUrl');
      localStorage.setItem('isUserLoggedIn','user');
      if (url) {
        this.router.navigateByUrl('/' + url);
        this.location.go('/' + url)
        localStorage.removeItem('routeUrl');
      } else {
        this.router.navigateByUrl('/'+this.language+'/home');
        this.location.go('/'+this.language+'/home')
        localStorage.removeItem('routeUrl');
      }
  }

  authorizeCode() {
    let user = localStorage.getItem('isUserLoggedIn');
    if(user === 'user'){
      this.signedIn = true ;
      this.initiateNavigation();
    }
  }
}
