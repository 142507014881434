import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppServicesService } from 'src/app/services/app-services.service';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { LanguageService } from 'src/app/services/language.service';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {
  highlightRegionContainer: any;
  parentContainer: any;
  logstatus: any;
  currentUser: any;
  translationData = [];
  languageId: string|null;
  isPageNotFound = false;
  language = '';
  participantDataToAnonymize: any;
  isProd:boolean = false;
  constructor(private router: Router, private route: ActivatedRoute, private awsAuth: AwsAuthService, private translationService: TranslationService, private languageService: LanguageService, private appservice: AppServicesService) { 
    this.languageId = this.route.snapshot.paramMap.get('languageId');
    if (this.languageId && environment.supported_language.includes(this.languageId)) {
      this.isPageNotFound = false
    } else {
      this.isPageNotFound = true;
    }

    const anonymizeParticipantData = sessionStorage.getItem('anonymizeParticipant');
    if (anonymizeParticipantData) {
      this.participantDataToAnonymize = anonymizeParticipantData;      
    }

  }

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    let featureFlag = this.translationService.featureFlags;
    this.isProd = featureFlag.general.tempProdFlag;
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.currentUser = localStorage.getItem('useLogged');
    // for prod environment, show the landing page
    // for non prod environment, if user is logged in, redirect to explore page
    if (!this.isProd && this.currentUser) {
      this.goToExplore();
    }
    this.appservice.dummyToken().subscribe({
      next: (data: any) => {
        if (data['access_token']) {
          localStorage.setItem('access_token', data.access_token)
        }
      },
    });
  }

  goToExplore(){
    this.router.navigateByUrl('/'+this.language+'/explore');
  }

  triggerLogin() {
    this.awsAuth.signIn();
    this.logstatus = true
  }

  closeAnonymizationPopup(status: boolean) {
    this.participantDataToAnonymize = undefined;
    
  }
 

}
