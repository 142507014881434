import { Component, Input, OnInit } from '@angular/core';
import { UserInfo } from 'src/app/components/interfaces/vehicle-page.interface';

@Component({
  selector: 'app-profile-mobile-view',
  templateUrl: './profile-mobile-view.component.html',
  styleUrls: ['./profile-mobile-view.component.scss']
})
export class ProfileMobileViewComponent implements OnInit {
  profileImage = "../../../../../../assets/images/profile-fallback.png";
  @Input()  profileDetails !: UserInfo;
  showOverlay: boolean = false;

  constructor() { }
  overlayActive: boolean = false;

  toggleOverlay() {
    this.overlayActive = !this.overlayActive;
  }

  ngOnInit(): void {
    
  }


}
