<div class="search-area-style">
    <span class="icon_search"></span>
    <input class="search-class"
     [formControl]="searchTerm" 
     type="text" 
     value="searchTerm.value"     
     id="searchInput"
     autocomplete="off"
     placeholder="{{'CP_LM_landingPage_searchText' | customTranslation: translationData}}..">     
</div>

<ng-container *ngIf="recentSearchData.length > 0">
    <div class="search-result">
    <ul *ngFor="let location of recentSearchData; let i = index">
      <li class="results-style" (click)="triggerDetailscall(location)">
        <img *ngIf="location.Icon" class="pin-icon" [src]="location.Icon" alt="..." />
        <div class="result-name" innerHTML="{{ location.name }}"></div>
      </li>
    </ul>
  <ul>
    <li *ngIf=" searchTerm?.value != null && searchTerm?.value != '' && isNoResult ">
      <div>No search results</div>
    </li>
  </ul>
</div>
</ng-container>
