
<ng-container *ngIf="!isShowErrorMessage" >
<div class="vehicle-page-main" [ngClass]="isScrolled ? 'min-car-card-box':''" *ngIf="isShowSpinner" (scroll)="onScroll($event)">
   
    <div class="vehicle-page-container">
        <div class="car-cards-section" >
            <div class="user-car">
                <app-car-component [isScrolled]="isScrolled" [vehicleInfo]="vehicleInfo" [storyItemTypeCount]="storyItemTypeCount"></app-car-component>
            </div>
        </div>
        <div class="main-sec">
            <div class="profile-about-social">
                <div id="prof-large">
                    <app-profile-component [profileDetails]="userInfo"></app-profile-component>
                </div>
                <div id="prof-mobile">
                    <app-profile-mobile-view [profileDetails]="userInfo"></app-profile-mobile-view>
                </div>
            </div>
          
            <div class="user-moments" *ngIf="stories">
                <app-moment-sharing [storyDetails]="stories"></app-moment-sharing>
            </div>
            <div class="message user-moments" *ngIf="stories == null">
                <div class="divider"></div>
                <p>There are no Stories from this car, yet. Use the Mercedes me Stories App to record a ride and publish the
                  Recording. You can also use the app to publish a special Moment with your car.
                </p>
              </div>
        </div>
        
    </div>
 </div>  
</ng-container> 
<div *ngIf="!isShowSpinner">
    <div class="spinner-border spinner">
    </div>
</div>

<div *ngIf="isShowErrorMessage"> 
    <div  class="no-vehicle">
        <div class="inner-container">
            <div class="no-vehicle-message">
            <h2>No data</h2>
            <p>Ooops something went wrong </p>
            </div>
        </div>
    </div>
</div>