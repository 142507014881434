export interface MystoriesResponse {
    stories: Story[]
    current_page: number
    total_elements: number
    total_pages: number
  }
  
  export interface Story {
    storyId: string
    ciamId: string
    description: Description[]
    hashtags: string[]
    dateTime: number
    location: Location
    itemType: string
    subType: string
    vinOrFin: string
    media: Media[]
    status: string
    isPublished: boolean
    createdAt: number
    updatedAt: number
    distance?: number
  }
  
  export interface Description {
    value: string
    key: string
  }
  
  export interface Location {
    lat: any
    long: any
    address?: string
    name?: string
  }
  
  export interface Media {
    id: string
    index: number
    name?: string
    fileName: string
    fileType: string
    key: string
    url: string
    status: string
    thumbnail_key_640?: string
    thumbnail_key_140?: string
    thumbnail_640?: string
    thumbnail_140?: string
  }

  export enum ESTORY_ITEM_TYPE{
    MOMENT = "MOMENT",
    TRACKRACE= "TRACKRACE",
  }
