import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, delay, forkJoin, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  public translatedArray: any = [];
  public languageType: string = '';
  public translationContent = new BehaviorSubject<any>(this.translatedArray);
  public sharetranslationData = this.translationContent.asObservable();
  public languageContent = new BehaviorSubject<any>(this.languageType);
  public sharelanguageType = this.languageContent.asObservable();
  private _featureFlags: any = null;
  private _featureFlagLink = environment.featureFlagLink

  constructor(private httpClient: HttpClient) { }
  sendtranslationValue(value: any) {
    this.translationContent.next(value);
  }
  sendSelectedLanguage(value: any) {
    this.languageContent.next(value);
  }

  setTranslationData(data: any) {
    this.translatedArray = data;
  }
  getTranslationData() {
    return this.translatedArray;
  }

  getTranslationDataAsObservable() : Observable<boolean> {    
      return this.translationContent.asObservable();
  }

  setLanguageType(type: any) {
    this.languageType = type;
  }
  getLanguageType() {
    return this.languageType;
  }


  getAllLanguages() {
    //https://api.phrase.com/v2/projects/44e8b7de2c6a909f3afeafc8993b3bb3/locales
    return this.httpClient.get(`https://api.phrase.com/v2/projects/44e8b7de2c6a909f3afeafc8993b3bb3/locales?access_token=a2a6d17811db4560969c2e8d6d261037a5afaae66d219f220110c293372c5b3b`);
  }


  phraseApiCallForTranslations(languageId: string): Observable<any> {
    if(languageId === 'de') {
      languageId = 'de-DE';
    } else if(languageId === 'en') {
      languageId = 'en-GB';
    }
    
    let page1 = this.httpClient.get(`https://api.phrase.com/v2/projects/44e8b7de2c6a909f3afeafc8993b3bb3/locales/${languageId}/translations?access_token=a2a6d17811db4560969c2e8d6d261037a5afaae66d219f220110c293372c5b3b&page=1&per_page=100`);
        
    return forkJoin([page1]);
  }

  public set featureFlags(flags: any) {
    this._featureFlags = flags;
  }

  public get featureFlags() {
    return this._featureFlags;
  }

  public getAllFeatureFlags(): Observable<any> {
    let featureFlags = this.httpClient.get(this._featureFlagLink)
    return featureFlags  
  }
}
