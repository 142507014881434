<div class="pop-screen">
    <div class="pop-ups">
        <div class="pop-up-content">
            <ng-container *ngIf="!isAnonymizeCalled">
                <h1>{{'CP_LM_Anonymization_Popup_title' | customTranslation: translationData}}</h1>
                <p>
                    {{'CP_LM_Anonymization_Popup_desc' | customTranslation: translationData}}
                </p>
                <div class="icon-region-main">
                    <div class="icon-item">
                        <span class="icon-placeholder">
                            <span class="icon icon_video"></span>
                            <span class="restricted-icon"></span>
                        </span>
                        <p>{{'CP_LM_Anonymization_Video_content' | customTranslation: translationData}}</p>
                    </div>
                    <div class="icon-item">
                        <span class="icon-placeholder">
                            <span class="icon icon_rank"></span>
                            <span class="restricted-icon"></span>
                        </span>
                        <p>{{'CP_LM_Anonymization_Lb_ranking' | customTranslation: translationData}}</p>
                    </div>
                    <div class="icon-item">
                        <span class="icon-placeholder">
                            <span class="icon icon_race"></span>
                            <span class="restricted-icon"></span>
                        </span>
                        <p>{{'CP_LM_Anonymization_Race_details' | customTranslation: translationData}}</p>
                    </div>

                </div>
                <p>{{'CP_LM_Anonymization_Confirmation' | customTranslation: translationData}}?</p>
                <div class="button-region right">
                    <button class="hallow" (click)="cancelAnonymization()"><span>{{'CP_LM_Cancel' | customTranslation: translationData}}</span></button>
                    <button [disabled]="isFormSubmitted" class="beta" (click)="anonymization()"><span>{{'CP_LM_Anonymization_Confirm_title' | customTranslation: translationData}}</span></button>
                </div>
            </ng-container>
            <ng-container *ngIf="isAnonymizeCalled">
                <div class="alert-icon-reg">

                    <div class="alert-icon ">
                        <span *ngIf="isAnonymizeFailed && !isAlreadyAnonimized" class="icon icon_failed"></span>
                       
                        <span *ngIf="isAnonymizeSuccess" class="icon icon_success"></span>
                        <span *ngIf="isAnonymizeFailed && isAlreadyAnonimized" class="icon icon_info"></span>
                        
                    </div>

                </div>
                <h3 *ngIf="isAnonymizeFailed && !isAlreadyAnonimized">{{'CP_DATA_ANONYMIZATION_FAIL_MSG' | customTranslation: translationData}}</h3>
                <h3 *ngIf="isAnonymizeFailed && isAlreadyAnonimized">{{'CP_DATA_ANONYMIZED' | customTranslation: translationData}}</h3>
                <h3 *ngIf="isAnonymizeSuccess">{{'CP_DATA_ANONYMIZED_SUCCESS' | customTranslation: translationData}}</h3>
                <div class="button-region center">
                    <button (click)="closePopup()" class="hallow"><span>{{'CP_LM_Ok' | customTranslation: translationData}}</span></button>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="overlay"></div>
</div>