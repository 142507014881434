import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { AppServicesService } from 'src/app/services/app-services.service';
import { DataService } from 'src/app/services/data.service';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';
import { EALL_TRACKS } from '../interfaces/map-view.interface';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnChanges {
  searchTerm: UntypedFormControl = new UntypedFormControl();
  showSearchResult: any;
  isNoResult: any;
  type: any = EALL_TRACKS.ALL;
  quickSearchData: any;
  searchResult: any;
  previousSearchTerm:string = "";

  @Output() public emitSearchResults = new EventEmitter<any>();
  @Output() public emitPaginatedResults = new EventEmitter<any>();
  @Output() public emitSearchTerm = new EventEmitter<any>();
  @Output() public emitTotalResultLength = new EventEmitter<any>();
  @Output() public emitTotalPages = new EventEmitter<any>();
  @Output() public goToPage = new EventEmitter<any>();



  @Input() public page: any;
  @Input() public pageNumber: any = 0;
  @Input() public searchFilter:any = EALL_TRACKS.ALL;
  circuits: any;
  emotionTours: any;
  access_Token: any;
  previousFilter:any;

  translationData = [];
  isOffroadFeatureEnabled: boolean = false;
  recentSearchData:any = [];
  featureFlags: any;

  constructor(public appHttpService: AppServicesService, public dataService: DataService, private translationService: TranslationService) { 
    this.isOffroadFeatureEnabled = environment.feature.enableOffTour;
  }

  ngOnInit(): void {
    this.featureFlags = this.translationService.featureFlags
    this.previousFilter = this.searchFilter
    let searchTerm = localStorage.getItem('searchTerm')
    if(searchTerm){
      this.searchTerm.setValue(searchTerm);
      localStorage.removeItem('searchTerm');
    }
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.appHttpService.dummyToken().subscribe({
      next: (data: any) => {
        if (data['access_token']) {
          this.access_Token = data.access_token;
        }
      },
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.searchResult = [];
    if(changes['pageNumber']){
    this.checkForFilters(this.pageNumber, true);
    } else{
    this.checkForFilters(0);

    }
    this.searchTerm.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((term: string) => {
        if (this.previousSearchTerm != term) {
          this.previousSearchTerm = term;
          this.showSearchResult = true;
          this.isNoResult = false;
          this.quickSearchData = [];
          this.searchBasedOnFilter(this.searchTerm?.value, 0, false);
        }

      });
  }

  triggerDetailscall(trackData:any){
    this.goToPage.emit(trackData);
    this.searchTerm.setValue('');
    localStorage.removeItem('searchTerm');
  }

  async searchCircuits(
    size: any,
    pageNo: any,
    name: any,
    circuits: any,
    emotionTour: any,
    offroad: boolean,
    moreLoad?: boolean
  ) {
    if (this.access_Token) {
      this.recentSearchData = []
      if(pageNo === 0 && name === '' && this.previousFilter !== this.searchFilter){
        this.dataService.setLoader(true);
        this.previousFilter = this.searchFilter;
      }
      if(name !== ''){
        this.featureFlags.landingPage.enableCircuits? circuits = true : circuits = false;
        this.featureFlags.landingPage.enableEmotionTour? emotionTour = true: emotionTour = false;
        this.featureFlags.landingPage.enableOffTour? offroad = true: offroad = false;
      }
      let response: any = await this.appHttpService
        .gobalSearch(size, pageNo, name, circuits, emotionTour, offroad, this.access_Token)
        .toPromise();
      this.isNoResult = false;      
      if (response) {
        if(name !== ''){
          this.recentSearchData = response.locations
        }
      this.searchTerm.value ? localStorage.setItem('searchTerm', this.searchTerm.value) : localStorage.setItem('searchTerm', '')
      this.dataService.setLoader(false);
        this.emitTotalResultLength.emit(response.totalElements);
        this.emitSearchTerm.emit(name);
        this.emitTotalPages.emit(response.totalPages);

        this.searchResult = response.locations;
        this.searchResult = this.searchResult?.map((item: any) => {
          switch(item.type) {
            case EALL_TRACKS.CIRCUIT:
              item.Icon = "assets/images/circuitpin.svg";
              break;
            case EALL_TRACKS.EMOTION_TOUR:
              item.Icon = "assets/images/emotionpin.svg";
              break;
            case EALL_TRACKS.OFF_ROAD:
              item.Icon = "assets/images/offroad-pin.svg";
              break;
          }
          return item;
        });
        if (this.searchResult?.length == 0 && name === '') {
          this.isNoResult = true;
          this.emitSearchResults.emit([]);
        } else {
          if (moreLoad) {
            this.emitPaginatedResults.emit(this.searchResult);
            
          } else {
          if(name === ''){
            this.emitSearchResults.emit(this.searchResult);
          }
            return this.searchResult;

          }
        }
      }
    }
  }


  public checkForFilters(pageNo:any, moreLoad?:any): void {
    this.searchBasedOnFilter(this.searchTerm?.value, pageNo, moreLoad)
  }

  public searchBasedOnFilter(searchTerm: string, pageNo:any, moreLoad?:any): void {
    const searchString = (val: string) => val ? val: ''
    switch(this.searchFilter) {
      case EALL_TRACKS.CIRCUIT: 
      this.searchCircuits(15, pageNo, searchString(searchTerm), true, false, false, moreLoad);
      break;
      case EALL_TRACKS.EMOTION_TOUR:
        this.searchCircuits(15, pageNo, searchString(searchTerm), false, true, false, moreLoad);
        break;
      case EALL_TRACKS.OFF_ROAD:
        this.searchCircuits(15, pageNo, searchString(searchTerm), false, false, true, moreLoad);
        break;
      case EALL_TRACKS.ALL:
        this.searchCircuits(15, pageNo, searchString(searchTerm), true, true, this.isOffroadFeatureEnabled, moreLoad);
        break;
    }
  }

}
