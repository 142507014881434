import { routes } from './../../app-routing.module';
import { ParticipantInfo } from './../../interfaces/event-info.interface';
import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { environment } from 'src/environments/environment';
import { Location } from "@angular/common";
import { ParticipantInfoDB } from './participant-info.db';
import { AppServicesService } from 'src/app/services/app-services.service';
import { EventInfoResponse } from 'src/app/interfaces/event-info.interface';
import { LandingPageComponent } from '../landing-page/landing-page.component';
import { TourDbService } from '../landing-page/landingpage.db';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/services/translation.service';
import { LanguageService } from 'src/app/services/language.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-ciam-confirm-link',
  templateUrl: './ciam-confirm-link.component.html',
  styleUrls: ['./ciam-confirm-link.component.scss']
})
export class CiamConfirmLinkComponent implements OnInit {
  eventInfoResponse : EventInfoResponse = <any>null;
  logstatus: any;
  auth = Auth;
  logoutUrl: string = environment.amplifyConfig.oauth.redirectSignOut;
  isCiamLinked: boolean = false;
  isConfirmClicked:  boolean = false;
  participants: any;
  profileImage : any;
  isProfileImageUrl: boolean = false;
  eventStartDate: any;
  eventEndDate: any;
  isSpinnerLoads:boolean = true;
  translationData = [];
  selectedLang:any;
  language: string = '';
  constructor(private _authService: AwsAuthService, private _location: Location, private _db: TourDbService,
    private _appService: AppServicesService, private router: Router, private translationService: TranslationService,
    private languageService: LanguageService)
   { 
    // this._db.openDb();
    let selectedLanguage = localStorage.getItem('language');
    if (!selectedLanguage) {
      selectedLanguage = 'en-GB';
    }
    this.selectedLang = selectedLanguage;
   }

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.getParticipantInfo();
    this._db.getParticipant(this.participants.participantId)
    .then((data: any) => {
      if (data) {
        console.log('Data saved successfully');
      }
    });
    

    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

 public getParticipantInfo(): void{

    this.participants= sessionStorage.getItem('partcipationDetails');
    let userLoggedIn = localStorage.getItem('useLogged');
    const langInUrl = this.router.url.split('/')[1];
    if(!this.participants || userLoggedIn!="true" || !environment.supported_language.includes(langInUrl)){
      this.router.navigateByUrl('/'+this.language+'/404');
      return;
    }
    if (this.participants) {
      this.participants = JSON.parse(this.participants);
    }   
    this._appService.getParticipantInfo(this.participants.participantId, this.participants.participantKey).subscribe((data: EventInfoResponse)=>{
      if(data){
        this.isCiamLinked = data.participantInfo.isCiamLinked;
        
        this.eventInfoResponse = data;
        // this.isProfileImageUrl= true;
        this.profileImage = data.ciamInfo.profileImageUrl;
        this.isSpinnerLoads = false;
        this.eventStartDate = new Date(data.eventInfo?.startDate);
        this.eventEndDate = new Date(data.eventInfo?.endDate);
        this.languageService.participantLocale = data.participantInfo.participantLocale ?? 'de-DE';
        const searchDataCached = {
          participantId: this.participants.participantId,
          response: this.eventInfoResponse,
        }
        this._db.getParticipant(this.participants.participantId).then((response: any) => {
          if (response) { // check if table is there
            this._db.updateParticipant(this.participants.participantId, searchDataCached);
          } else {
            this._db.addParticipant(searchDataCached);
          }
          if (this.isCiamLinked) {
            this.router.navigateByUrl('/' + this.language + '/' + this.participants.participantId + '/my-race-details');
          }
        })
      }
    }, error => {
      this.isSpinnerLoads = false;
      // What should happen here??? I got error "Different CIAM Account Linked"
      this.router.navigateByUrl('/'+this.language+'/404');
      console.log('Error while fetching participant info');
    })

   

  }
  // triggerLogin() {
  //   this.awsAuth.signIn();
  //   this.signedIn = true;
  // }
public confirm() : void{
  this.isConfirmClicked=true;
  this._appService.getCiamLink(this.participants?.participantId, this.participants?.participantKey
    ).subscribe(
    (response) => {
      this.router.navigateByUrl('/'+this.language+'/'+this.participants.participantId+'/my-race-details');
    }, (error: HttpErrorResponse) => {
      const errorStatusCodes = [400]
      // CIAM is already linked then show my details page
      if (errorStatusCodes.includes(error.status)) {
        // We are not supposed to show any race details right???
        this.router.navigateByUrl('/'+this.language+'/'+this.participants.participantId+'/my-race-details');
      } else {
        this.router.navigateByUrl('/'+this.language+'/404');
      }
    }
  );
}

 async  logout() {
   let paricipantInfo = sessionStorage.getItem("participantInfo");
  let pariticipantVerificationUrl =   "verify/"+paricipantInfo;
    localStorage.removeItem('useLogged');
    localStorage.removeItem('routeUrl');
    // this.logstatus.unsubscribe();
    // emit subject with true
  
    sessionStorage.setItem('ciamClicked','true');
    await this.auth.signOut()

      .then((data) => {
        setTimeout(()=> {

          window.open(pariticipantVerificationUrl, "_self");

        }, 1000);

      })  

  }


}
