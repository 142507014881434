export interface VehicleInfoResponse {
    stories: Story[]
    userInfo: UserInfo
    vehicleInfo: VehicleInfo
    storyItemTypeCount: StoryItemTypeCount
    current_page: number
    total_elements: number
    total_pages: number
  }
  
  export interface Story {
    storyId: string
    ciamId: string
    description: Description[]
    hashtags: any[]
    dateTime: any
    location: Location
    itemType: string
    subType: string
    vinOrFin: string
    media: Media[]
    status: string
    isPublished: boolean
    createdAt: number
    updatedAt: number
    distance: any
    raceData: RaceData
  }
  
  export interface Description {
    value: string
    key: string
  }
  
  export interface Location {}
  
  export interface Media {
    id: string
    index: number
    name: string
    fileType: string
    mediaType: string
    status: string
    url: string
    thumbnail_640: string
    thumbnail_140: string
  }
  
  export interface RaceData {
    lapCount: number
    bestLapDuration: number
    raceStartTime: number
    trackName: string
    headUnitType: string
    raceName: string
  }
  
  export interface UserInfo {
    ciamId: string
    nickName: string
    bio: string
    name: string
    profileImage: any
    link: string
    firstName: string
    lastName: string
  }
  
  export interface VehicleInfo {
    vehicleModel: string
    vehicleUrl: string
  }
  
  export interface StoryItemTypeCount {
    totalExperiences?: number
    totalMoments?: number
  }