export interface MapViewResponse {
    circuitInfoList?: (CircuitInfoListEntity)[] | null;
    emotionTourList?: (EmotionTourListEntity)[] | null;
    offRoadTourList?: (OffRoadTourListEntity)[] | null;
  }
  export interface CircuitInfoListEntity {
    guid: string;
    name: string;
    visibility: string;
    continent: string;
    country: string;
    coordinates: string;
    layoutsAvailable: boolean;
    layouts: number;
    createdDate: string;
    updatedDate: string;
    description?: string | null;
    media?: Media | null;
    encodedPolyline?: string | null;
  }
  export interface Media {
    image?: (ImageEntity)[] | null;
  }
  export interface ImageEntity {
    id: string;
    name: string;
    originalUrl: string;
    thumbnailUrl: string;
  }
  export interface EmotionTourListEntity {
    guid: string;
    name: string;
    visibility: string;
    description?: string | null;
    coordinates: string;
    createdDate: string;
    updatedDate: string;
    encodedPolyline?: string | null;
    annotationsCount: number;
    trackLength: number;
    media?: Media | null;
  }
  
  export interface OffRoadTourListEntity {
    guid: string;
    name: string;
    visibility: string;
    description?: string | null;
    coordinates: string;
    createdDate: string;
    updatedDate: string;
    encodedPolyline?: string | null;
    annotationsCount: number;
    trackLength: number;
  }

  export interface SearchResponse {
    locations: (LocationsEntity)[];
    totalElements: number;
    totalPages: number;
    pageNumber: number;
    pageSize: number;
  }
  export interface LocationsEntity {
    guid: string;
    name: string;
    type: string;
    visibility: string;
    coordinates: string;
    createdDate: string;
    updatedDate: string;
    owner?: Owner | null;
    description?: string | null;
    totalNumberOfLayouts?: number | null;
    layouts?: (LayoutsEntity)[] | null;
  }
  export interface Owner {
    ciamId: string;
    nickName?: string | null;
    bio?: string | null;
  }
  export interface LayoutsEntity {
    guid: string;
    name: string;
    type: string;
    visibility: string;
    coordinates: string;
    createdDate: string;
    updatedDate: string;
    owner: Owner1;
    trackLength: number;
    noOfSectors: number;
  }
  export interface Owner1 {
    ciamId: string;
  }
  

  export enum EALL_TRACKS {
    CIRCUIT = 'CIRCUIT',
    EMOTION_TOUR = 'EMOTION_TOUR',
    OFF_ROAD = 'OFFROAD_TOUR',
    ALL = 'ALL'
  }
  