<div *ngIf="annotationDetails" id="annotation-detail-main" class="details-container">
    <div id="annotation-aside" class="col-md-4">
        <div class="main-heading"><span (click)="closePage()" class="arrow"></span>
            <p class="title"><span>{{annotationDetails.properties.name}}</span></p>
        </div>
        <div class="overview">
            <div class="overview-data">
                <div>
                    {{annotationDetails.properties.feature_type}}  {{annotationDetails.properties.route_position}} Km
                    {{'CP_LM_tourDetailsPage_fromStart' | customTranslation: translationData}}
                </div>
                <div *ngIf="annotationDetails.properties.official_website_url" class="overview-table">
                    <div class="overview-table-row">
                        <div class="col-md-4">{{'CP_LM_circuitDetailsPage_website' | customTranslation: translationData}}</div>
                        <div class="website-url">
                            <a href={{annotationDetails.properties.official_website_url}}>
                                <span>{{annotationDetails.properties.official_website_url}}</span>
                            </a>
                        </div>
                    </div>
                    <div *ngIf="annotationDetails.properties.address" class="overview-table-row">
                        <div class="col-md-4">{{'CP_LM_circuitDetailsPage_address' | customTranslation: translationData}}</div>
                        <div>
                            <u>{{annotationDetails.properties.address.suburb}},{{annotationDetails.properties.address.municipality}}</u>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <div id="annotation-main"  class="col-md-8 details-section">
        <div class="image-box overview-image">
            <img [src]="annotationDetails.properties.media[0].url" *ngIf="annotationDetails.properties.media" alt="image" />
        </div>
        <div *ngIf="annotationDetails.properties.short_ai_summary" class="cover-overview">
            <div class="col-md-4 overview-title">
                <span>{{'CP_LM_annotationDetailsPage_overview' | customTranslation: translationData}}</span>
            </div>
            <div class="col-md-8 summary-style">
                {{annotationDetails.properties.short_ai_summary}}
                <p class="wiki-url"> 
                    <span class="wiki-icon"></span>
                    <span><a class="wikipedia" href="{{annotationDetails.properties.wikidata_url}}" target="_blank" rel="noopener">wikidata</a></span>
                </p>
            </div>
        </div>
        <div class="wiki-data-style">
            <div class="image-box" *ngIf="annotationDetails.properties.media.length > 1"> 
                <img [src]="annotationDetails.properties.media[1].url" *ngIf="annotationDetails.properties.media" alt="image"/>
            </div>
            <div class="image-box" *ngIf="annotationDetails.properties.wikipedia" class="wiki-summary-style">
                <span>{{annotationDetails.properties.wikipedia.extract}} </span>               
            </div>
            <div class="image-box" *ngIf="annotationDetails.properties.media.length > 2"> 
                <img [src]="annotationDetails.properties.media[2].url" *ngIf="annotationDetails.properties.media" alt="image" />
            </div>
            <div class="image-box" *ngIf="annotationDetails.properties.media.length > 3"> 
                <img [src]="annotationDetails.properties.media[3].url" *ngIf="annotationDetails.properties.media" alt="image"/>
            </div>
            <div class="image-box" *ngIf="annotationDetails.properties.media.length > 4"> 
                <img [src]="annotationDetails.properties.media[4].url" *ngIf="annotationDetails.properties.media" alt="image"/>
            </div>
            <div class="image-box" *ngIf="annotationDetails.properties.media.length > 5"> 
                <img [src]="annotationDetails.properties.media[5].url" *ngIf="annotationDetails.properties.media" alt="image" />
            </div>
        </div>
    </div>
</div>