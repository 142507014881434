import { EventInfo } from './../../interfaces/event-info.interface';
import { Component, Input, OnInit } from '@angular/core';
import { AppServicesService } from 'src/app/services/app-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TourDbService } from '../landing-page/landingpage.db';
import { TranslationService } from 'src/app/services/translation.service';
import { env } from 'process';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ciam-link',
  templateUrl: './ciam-link.component.html',
  styleUrls: ['./ciam-link.component.scss'],
})
export class CiamLinkComponent implements OnInit {
  eventName: string = '';
  translationData = [];
  eventBanner = '';
  defaultBanner = '../../../assets/images/banner.png';
  participants: any;
  traceId: any;
  constructor(
    private application: AppServicesService,
    private router: Router,
    private languageService: LanguageService,
    private _db: TourDbService,
    private translationService: TranslationService,
    private _activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.translationService
      .getTranslationDataAsObservable()
      .subscribe((translationData: any) => {
        this.translationData = translationData;
      });
    this.participants = sessionStorage.getItem('partcipationDetails');
    const languageFromUrl = this._activatedRoute.snapshot.paramMap.get('languageId');
    if (!this.participants || !languageFromUrl || environment.supported_language.indexOf(languageFromUrl) === -1){ 
      this.router.navigate(['/' + languageFromUrl + '/404']);
    }
    if (this.participants) {
      this.participants = JSON.parse(this.participants);
    }
    this._activatedRoute.params.subscribe(params => {
      let id = params['participantId'];
      if (id === this.participants.participantId) { 
        this._db.getParticipant(this.participants.participantId).then((data: any) => {
          if (data) {
            this.eventName = data.response.eventInfo.eventName;
            this.eventBanner = data.response.eventInfo.eventBannerUrl;
            this.languageService.participantLocale = data.response.participantInfo.participantLocale ?? 'de-DE'
          }
        });
      } else {
        const languageFromUrl = this._activatedRoute.snapshot.paramMap.get('languageId');
        this.router.navigate(['/' + languageFromUrl + '/404']);
      }
    });
  }
}