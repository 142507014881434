<!-- Moment card -->
<div *ngIf="story.itemType === 'MOMENT'">
    <div
      class="card-flex-box"
      [ngClass]="story.itemType === 'MOMENT' ? 'moment-container' : ''"
    >
      <div class="img-reg">
        <div class="media-container">
          <div *ngFor="let media of story.media" class="media-item">
            <ng-container>
              <img class="img-fluid" [src]="media.url" alt="Image" />
            </ng-container>
          </div>
        </div>
      </div>
      <div class="info-panel-bottom-reg">
        <h2>{{ story.description[0].value }}</h2>
        <div class="rowele">
          <!-- <span>04/03/2024</span> -->
          <app-tags-component
            [tagClass]="'tag'"
            [tagTitle]="date | date : 'dd.MM.yyyy'"
          ></app-tags-component>
        </div>
      </div>
      <div class="tag-item slideIndex" *ngIf="story.media.length > 1">
        <span class="slideerNumber"
          >{{ currentIndex + 1 }}/{{ story.media.length }}</span
        >
      </div>
      <div *ngIf="story.media.length > 1">
        <button
          (click)="scroll(-1)"
          class="arrow-button left"
          *ngIf="this.currentIndex != 0"
        ></button>
        <button
          (click)="scroll(1)"
          class="arrow-button right"
          *ngIf="this.currentIndex != story.media.length - 1"
        ></button>
      </div>
    </div>
  </div>
  
  <!-- Track Race -->
  <div *ngIf="story.itemType === 'TRACKRACE'">
    <div
      class="card-flex-box"
      [ngClass]="story.itemType === 'TRACKRACE' ? 'trackRace-container' : ''"
    >
      <div class="media-container">
        <div class="media-item" *ngFor="let media of story.media; let i = index">
          <ng-container>
            <img
              class="img-fluid"
              *ngIf="media.mediaType == 'image'"
              [src]="media.url"
            />
            <video
              *ngIf="media.mediaType == 'VIDEO'"
              class="img-fluid"
              [muted]="!volume"
              autoplay
              preload="none"
              [loop]="true"
            >
              <source [src]="media.url" type="video/mp4" />
            </video>
          </ng-container>
        </div>
      </div>
  
    <div class="info-panel-bottom">
      <!-- Event Name -->
      <h2 class="event-title">{{ story.raceData.raceName }}</h2>
      <div class="rowele">
        <!-- lap Count -->
        <app-tags-component
          [tagClass]="'tag-red'"
          [tagTitle]="story.raceData.lapCount + ' laps'"
        ></app-tags-component>
        <!-- Race start time -->
        <app-tags-component
          [tagClass]="'tag'"
          [tagTitle]="story.raceData.bestLapDuration + ' min'"
        ></app-tags-component>
  
        <!-- time and date -->
        <app-tags-component
          [tagClass]="'tag'"
          [tagTitle]="date | date : 'dd.MM.yyyy'"
        ></app-tags-component>
      </div>
    </div>
    <div class="tag-item slideIndex" *ngIf="story.media.length > 1">
      <span class="slideerNumber"
        >{{ currentIndex + 1 }}/{{ story.media.length }}</span
      >
    </div>
    <div *ngIf="story.media.length > 1">
      <button
        (click)="scroll(-1)"
        class="arrow-button left"
        *ngIf="this.currentIndex != 0"
      ></button>
      <button
        (click)="scroll(1)"
        class="arrow-button right"
        *ngIf="this.currentIndex != story.media.length - 1"
      ></button>
    </div>
  </div>
  </div>
  
  <!-- Emotion Tour -->
  <div *ngIf="story.itemType === 'EMOTIONTOUR'">
    <div class="card-flex-box">
      <div class="image">
        <div class="media-container">
          <div class="media-item">
            <ng-container>
              <img
                class="img-fluid"
                src="../../../../../../assets/images/1st-card.png"
              />
              <!-- <video *ngIf="media.mediaType=='VIDEO'" class="img-fluid" [muted]="!volume" autoplay preload="none"
                       [loop]="true" >
                      <source [src]="media.url" type="video/mp4"/>
                  </video> -->
            </ng-container>
          </div>
        </div>
      </div>
      <div class="info-panel-bottom">
        <!-- Em Title -->
        <h2 class="event-title">Emotion Tour</h2>
        <div class="rowele">
          <!-- Emotion Tour  -->
          <app-tags-component
            [tagClass]="'tag-blue'"
            [tagTitle]="'Emotion Tour'"
          ></app-tags-component>
          <!-- Total distance -->
          <app-tags-component
            [tagClass]="'tag'"
            [tagTitle]="'33 km'"
          ></app-tags-component>
          <!--  start time -->
          <app-tags-component
            [tagClass]="'tag'"
            [tagTitle]="' 2:24 hrs'"
          ></app-tags-component>
  
          <!-- time and date -->
          <app-tags-component
            [tagClass]="'tag'"
            [tagTitle]="'03.10.2023'"
          ></app-tags-component>
        </div>
      </div>
      <!-- <div class="tag-item slideIndex" *ngIf="story.media.length > 1">
            <span class="slideerNumber">{{currentIndex+1}}/{{story.media.length}}</span>
        </div>
        <div *ngIf="story.media.length>1">
            <button (click)="scroll(-1)" class="arrow-button left" *ngIf="this.currentIndex != 0"></button>
            <button (click)="scroll(1)" class="arrow-button right"
                *ngIf="this.currentIndex != story.media.length-1"></button>
        </div> -->
    </div>
  </div>
  
  
  
  