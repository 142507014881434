import { RouterModule, Routes } from '@angular/router';

import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LoginComponent } from './components/login/login.component';
import { NgModule } from '@angular/core';
import { NoRouteComponent } from './no-route/no-route.component';
import { TrackLoaderComponent } from './track-loader/track-loader.component';
import { CircuitDetailsPageComponent } from './components/circuit-details-page/circuit-details-page.component';
import { LibraryComponent } from './components/library/library.component';
import { AuthGuard } from './services/auth.guard';
import { CiamLoginComponent } from './components/ciam-login/ciam-login.component';
import { CiamLinkComponent } from './components/ciam-link/ciam-link.component';
import { VerifyComponent } from './components/verify/verify.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { ToggleGuard } from './services/toggle-guard.guard';
import { RouterLanguageGaurdGuard } from './services/router-language-gaurd.guard';
import { AnonymizeComponent } from './components/anonymize/anonymize.component';
import { MyStoriesListComponent } from './modules/my-stories/pages/my-stories-list/my-stories-list.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { VehiclePageComponent } from './modules/my-stories/pages/vehicle-page/vehicle-page.component';
import { LogoutHandlerComponent } from './components/logout-handler/logout-handler.component';
import { CiamConfirmLinkComponent } from './components/ciam-confirm-link/ciam-confirm-link.component';
import { ProdEnabledGuard } from './services/prod-enabled.guard';
import { PrivacyPageComponent } from './components/shared/pages/privacy-page/privacy-page.component';
import { ProviderPageComponent } from './components/shared/pages/provider-page/provider-page.component';

export const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: ':languageId/explore',
    component: LandingPageComponent,
    canActivate: [ToggleGuard],
    data: {page: 'enableLandingPage'}
  },
  {
    path: ':languageId/home',
    component: MainPageComponent,
  },
  {
    path: 'temp-login',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: CiamLoginComponent,
  },  
  {
    path: 'anonymize/:partipationInfo', 
    children: [
      {
        path: '**',
        children: [],
        canActivate: [RouterLanguageGaurdGuard]
      }
    ],
    canActivate: [RouterLanguageGaurdGuard]
  },
  {
    path: ':languageId/anonymize/:partipationInfo',
    component: AnonymizeComponent,
  },
  {
    path: 'logout',
    component: LogoutHandlerComponent,
  },
  {
    path: ':languageId/link-participant-id',
    component: CiamConfirmLinkComponent,
  },
  {
    path: ':languageId/explore/circuit/:name',
    component: CircuitDetailsPageComponent,
    canActivate: [ToggleGuard],
    data: {page: 'enableCircuitDetails'}

  },
  {
    path: ':languageId/explore/emotion-tour/:name',
    component: TrackLoaderComponent,
    canActivate: [ToggleGuard],
    data: {page: 'enableTourDetails'}
  },  
  {
    path: ':languageId/:participantId/my-race-details',
    component: CiamLinkComponent,
  },
  {
    path: 'verify/:partipationInfo', 
    children: [
      {
        path: '**',
        children: [],
        canActivate: [RouterLanguageGaurdGuard]
      }
    ],
    canActivate: [RouterLanguageGaurdGuard]
  },
  {
    path: ':languageId/verify/:partipationInfo',
    component: VerifyComponent,
  },
  {
    path: ':languageId/stories',
    component: MyStoriesListComponent,
    canActivate: [ ProdEnabledGuard],
  },
  {
    path :'vehicle-page/:vehicleId',
    component: VehiclePageComponent,
    canActivate: [ToggleGuard],
  },
  {    
    path: ':languageId/privacy',
    component: PrivacyPageComponent,
  },
  {
    path: ':languageId/provider',
    component: ProviderPageComponent,
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent,
  },
  {

      path: 'home',

      component: MainPageComponent,

    },
    {
      path: ':languageId/404',
      component: NoRouteComponent,
    },
  
{

    path: 'processing',

    component: CiamLinkComponent,

  },
  { path: '**', component: NoRouteComponent },
    
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}