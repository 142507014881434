<div id="header-reg-main" [ngClass]="{'header-min': isHeaderMin }">
  <div id="header-wp" class="header-top-bar">
    <div id="mb-brand-reg">
      <div id="brand-center">
        <a id="mbbrand" class="header-link" href="/">
          <ng-container *ngIf="videoplayed === 'false'">
            <video id="brand-ani" (ended)="vidEnded()" role="presentation" preload="true" playsinline autoplay disablePictureInPicture [muted]="true"  class="brandhub-header__video" #video >
              <source src="assets/media/original.mp4" type="video/mp4" />
            </video>
          </ng-container>
          <picture class="brandhub-header__logo">
            <img src="assets/media/MB-star_n_web.svg" alt="Mercedes-Benz brand logo" class="brandhub-picture brandhub-picture--contain" />
          </picture>
        </a>
      </div> 
    </div>
    <div [ngClass]="showMenu? 'menu-navigation-animation':'menu-navigation-animation-goup'" class="l1-menu">
      <ng-container>
        <span class="menu-item top-menu-font"> 
        <span class="glow-button lang-padding" [ngClass]="this.selectedLang =='de-DE'? 'language-active':''" (click)="switchLanguage(languageToDisplay)">{{languageToDisplay}}</span> | 
        <span class="glow-button lang-padding" [ngClass]="this.selectedLang =='en-GB'? 'language-active':''" (click)="switchLanguage('English')">English</span>
      </span>
      </ng-container>
      <ng-container >
        <span class="menu-item top-menu-font privacy"> 
          <span (click)="navigateToProviderPage()">{{'CP_LM_footer_provider' | customTranslation: translationData}}</span> / 
          <span (click)="navigateToPrivacyPage()">{{'CP_LM_footer_privacy' | customTranslation: translationData}}</span>
        </span>
      </ng-container>   
      <ng-container *ngIf="showLoginLink">
        <div class="login-button-class" [ngClass]="showMenu? 'menu-navigation-animation':'menu-navigation-animation-goup'">
          <span class="menu-item top-menu-font glow-button" (click)="triggerLogin()">{{'CP_LM_landingPage_login' | customTranslation: translationData}}</span>
        </div>
      </ng-container>
      <div *ngIf="showMenu || currentUser" class="login-class">
        <ng-container *ngIf="showMyProfileLink">
          <div id="user-profile" class="desk-only" *ngIf="!isProd">
            <span class="prof-image" (click)="profileClicked()"></span>
            <span class="profile-name" (click)="profileClicked()">
                <span class="first-name">My </span>
                <span class="last-name">Profile</span>
            </span>
            <div class="profile-menu">
                <ul>
                    <li (click)="logout()">
                      <span class="glow-button">Logout</span>
                    </li>
                </ul>
            </div>
        </div>
        </ng-container>
        
      </div>   
    </div>
    <ng-container>
      <div class="menu-btn-top" [ngClass]=" !showBottomMenu? '': 'menu-close'" >
        <span (click)="showDropDownInMobile()" class="menu-item-menu-btn">Menu</span>
        <span (click)="showDropDownInMobile()" class="menu-item-menu-btn">Close</span>
      </div>
    </ng-container>
    

    
  </div>
  <div class="header-top-bar-backdrop"></div>
  <ng-container>
  <div class="header-l2-menu" *ngIf="!isProd">
    <div [ngClass]="showMenu? 'header-navigation-animation':'header-navigation-animation-goup'" (mouseleave)="hideDropMenu()" id="header-profile" *ngIf="showHeader && toggleHeader" class="header-navigation">
      <span  id="home" [ngClass]="this.selectedCategory =='/explore'? 'active-menu':''" (click)="goToHome()"><span class="menu-item">{{'CP_LM_landingPage_explore' | customTranslation: translationData}}</span></span>
      <span  id="mestories" [ngClass]="this.selectedCategory =='/stories'? 'active-menu':''" (click)="handleMyStoriesNavigationClick()"><span class="menu-item">My stories</span></span>        
    </div>
  </div>
  <div class="header-l2-menu-backdrop"></div>
</ng-container>

<ng-container >
  <div [ngClass]="showBottomMenu? 'mobile-list-comedown':'mobile-list-goup'" class="mobile-list">
    <div class="mobile-nav-wrapper">
      <div class="mobile-main-nav" *ngIf="!isProd">
        <span id="mobilehome" [ngClass]="this.selectedCategory =='/explore'? 'active-menu':''" class="menu-item glow-button" (click)="goToHome()">Explore</span>
        <span id="mobilemestories" [ngClass]="this.selectedCategory =='/home'? 'active-menu':''" class="menu-item glow-button" (click)="handleMyStoriesNavigationClick()">My stories</span>
      </div>
      <div class="mobile-bottom-nav">
        <div class="mobile-login-block">
          <ng-container *ngIf="showLoginLink">
            <span class="menu-item-mobile-menu" (click)="triggerLogin()">{{'CP_LM_landingPage_login' | customTranslation: translationData}}</span>
          </ng-container>
          <ng-container *ngIf="showMyProfileLink">
            <div id="user-profile-mobile">
              <div id="user-profile-info-mobile">
                <span class="prof-image" (click)="profileClicked()" ></span>
                <span class="profile-name" (click)="profileClicked()" >
                    <span class="first-name">My </span>
                    <span class="last-name">Profile</span>
                </span>
              </div>
              <div class="profile-menu">
                  <ul>
                      <li (click)="logout()">
                        <span class="glow-button">Logout</span>
                      </li>
                  </ul>
              </div>
          </div>
          </ng-container>
        </div>
        <span class="menu-item">
          <span class="glow-button lang-padding" [ngClass]="this.selectedLang =='de-DE'? 'language-active':''" (click)="switchLanguage('languageToDisplay')">{{languageToDisplay}}</span> |
          <span class="glow-button lang-padding" [ngClass]="this.selectedLang =='en-GB'? 'language-active':''" (click)="switchLanguage('English')">English</span>
        </span>
        <span class="menu-item glow-button"> 
          <span (click)="navigateToProviderPage()">{{'CP_LM_footer_provider' | customTranslation: translationData}}</span> / 
          <span (click)="navigateToPrivacyPage()">{{'CP_LM_footer_privacy' | customTranslation: translationData}}</span>
        </span>
      </div>
    </div>
  </div>
</ng-container>
  
</div>

<div *ngIf="isProfileClicked">
  <app-edit-profile (cancelClicked)="cancelClicked($event)"></app-edit-profile>
</div>