import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyStoriesService {
  
  token = localStorage.getItem('access_token');
  baseUrl = environment.myStoriesUrl;


  constructor(private _httpClient: HttpClient) { }

  fetchStories(lat: number, long: number, pageNumber: number, pageSize: number): Observable<any> {
    const url = `${this.baseUrl}?lat=${lat}&long=${long}&page_number=${pageNumber}&page_size=${pageSize}`
    return this._httpClient.get(url);
  }

  getVehicleDetails(vehicleId: any, pageNumber : number, pageSize: number): Observable<any>{
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('username', 'mestoriesuser')
      .set('password', 'mestoriesuser@1234');

    const base_url = environment.vehiclePageUrl
    const body = {
      "encoded_text": `${vehicleId}=`
    }
    const url= `${base_url}/vehicle/sharing?page_size=${pageSize}&page_number=${pageNumber}`
    return this._httpClient.post( url, body,{headers: headers} );
    // const response = require('../../../../test.json')
    // return of (response);
  }


}