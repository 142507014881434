import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TrackLoaderComponent } from './track-loader/track-loader.component';
import { NoRouteComponent } from './no-route/no-route.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrackDetailsPageComponent } from './components/track-details-page/track-details-page.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { EmotionTourSummaryComponent } from './components/emotion-tour-summary/emotion-tour-summary.component';
import { ClustersListComponent } from './components/clusters-list/clusters-list.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { SearchComponent } from './components/search/search.component';
import { ClusterCardComponent } from './components/cluster-card/cluster-card.component';
import { AnnotationDetailsComponent } from './components/annotation-details/annotation-details.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { CircuitDetailsPageComponent } from './components/circuit-details-page/circuit-details-page.component';
import { TrackDetailsComponent } from './components/track-details/track-details.component';
import { GeoPathComponent } from './components/geo-path/geo-path.component';
import { LibraryComponent } from './components/library/library.component';
import { CiamLoginComponent } from './components/ciam-login/ciam-login.component';
import { RefreshTokenComponent } from './components/refresh-token/refresh-token.component';
import { CiamLinkComponent } from './components/ciam-link/ciam-link.component';
import { VerifyComponent } from './components/verify/verify.component';
import { TokenInterceptor } from './token.interceptor';
import { MainPageComponent } from './components/main-page/main-page.component';
import { TranslationModule } from './modules/translation.module';
import { SafePipe } from './pipes/safe.pipe';
import { AnonymizeComponent } from './components/anonymize/anonymize.component';
import { AnonymizePopupComponent } from './components/anonymize-popup/anonymize-popup.component';
import { TrackCardsComponent } from './components/track-cards/track-cards.component';
import { CommonModalComponent } from './components/common-modal/common-modal.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { ErrorHandlingInterceptor } from './interceptors/error-handling.interceptor';
import { ToastComponent } from './components/toast/toast.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { AwsAuthService } from './services/aws-auth.service';
import { MyStoriesModule } from './modules/my-stories/my-stories.module';
import { CiamConfirmLinkComponent } from './components/ciam-confirm-link/ciam-confirm-link.component';
import { LogoutHandlerComponent } from './components/logout-handler/logout-handler.component';
import { CommonModule } from '@angular/common';
import { TranslationService } from './services/translation.service';
import { firstValueFrom, tap } from 'rxjs';
import { PrivacyPageComponent } from './components/shared/pages/privacy-page/privacy-page.component';
import { ProviderPageComponent } from './components/shared/pages/provider-page/provider-page.component';

export function loadFeatureFlagsFactory(translationService: TranslationService): () => Promise<any> {
  return () => firstValueFrom(translationService.getAllFeatureFlags().pipe(
      tap((featureFlags: any) => {  
          translationService.featureFlags = featureFlags;
      })
  ));
}

@NgModule({
  declarations: [
    AppComponent,
    TrackLoaderComponent,
    NoRouteComponent,
    LoginComponent,
    TrackDetailsPageComponent,
    HeaderComponent,
    FooterComponent,
    EmotionTourSummaryComponent,
    ClustersListComponent,
    LandingPageComponent,
    SearchComponent,
    ClusterCardComponent,
    AnnotationDetailsComponent,
    NotFoundPageComponent,
    CircuitDetailsPageComponent,
    TrackDetailsComponent,
    GeoPathComponent,
    LibraryComponent,
    CiamLoginComponent,
    RefreshTokenComponent,
    CiamLinkComponent,
    VerifyComponent,
    MainPageComponent,
    SafePipe,
    AnonymizeComponent,
    AnonymizePopupComponent,
    TrackCardsComponent,
    CommonModalComponent,
    EditProfileComponent,
    ToastComponent,
    ComingSoonComponent,
    CiamConfirmLinkComponent,
    LogoutHandlerComponent,
    TrackDetailsComponent,
    PrivacyPageComponent,
    ProviderPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslationModule,
    MyStoriesModule, 
    CommonModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
              AwsAuthService,
              {
                provide: APP_INITIALIZER,
                useFactory: loadFeatureFlagsFactory,
                deps: [TranslationService],
                multi: true
            },
              {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true}],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }