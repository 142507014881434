
<div class="car">
    <div class="car-image-container" [ngClass]="isScrolled ? 'scrolled':''">

        <div class="car-carousel">
            
            <div class="car-carousel-inner">
                <div class="car-card active">
                   <div class="car-card-box">
                    <img class="car-image" [src]="vehicleUrl" alt="">
                    <div class="car-infos">
                        <h1 class="car-name"> {{vehicleModel}} </h1>
                        <div class="car-tags">
                            <div class="tag" *ngIf="isCountOfExperienceIsOne">{{storyItemTypeCount?.totalExperiences}} Experience</div>
                            <div class="tag" *ngIf="isCountOfExperienceMore ">{{storyItemTypeCount?.totalExperiences}} Experiences</div>

                            <div class="tag" *ngIf="isExperienceEmpty">Nothing recorded yet</div>
                            <div class="tag" *ngIf="isCountOfMomentMore">{{storyItemTypeCount?.totalMoments}} Moments</div>
                            <div class="tag" *ngIf="isCountOfMomentIsOne">{{storyItemTypeCount?.totalMoments}} Moment</div>

                        </div>
                    </div>
                   </div>
                   <div class="min-card-box">
                     <div class="car-info-min">
                        <h4 class="car-name-min"> {{vehicleModel}} </h4>
                        <div class="car-tags">
                            <div class="tag" *ngIf="isCountOfExperienceIsOne">{{storyItemTypeCount?.totalExperiences}} Experience</div>
                            <div class="tag" *ngIf="isCountOfExperienceMore ">{{storyItemTypeCount?.totalExperiences}} Experiences</div>

                            <div class="tag" *ngIf="isExperienceEmpty">Nothing recorded yet</div>
                            <div class="tag" *ngIf="isCountOfMomentMore">{{storyItemTypeCount?.totalMoments}} Moments</div>
                            <div class="tag" *ngIf="isCountOfMomentIsOne">{{storyItemTypeCount?.totalMoments}} Moment</div>

                        </div>
                     </div>
                     <img class="car-image-min" [src]="vehicleInfo?.vehicleUrl" alt="">

                   </div>
                </div>   
    </div>
   
</div>