import { Component, OnInit } from '@angular/core';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout-handler',
  templateUrl: './logout-handler.component.html',
  styleUrls: ['./logout-handler.component.scss']
})
export class LogoutHandlerComponent implements OnInit {
  isProdEnvironment: boolean = true;

  constructor(private _awsauthService: AwsAuthService, private translationService:TranslationService) { }
  isCiamClicked: any;
  reLoginForToken: any;
  signedIn = false;
  isCiamLinkingInProgress = false;
  ngOnInit(): void {
    // declare a variable that checks if log out is from header or from ciam
    // we will read this from subject response.
    // IF LOGOUT is from CIAM
    // then redirect to CIAM - window.open(..., self)
    // ELSE 
    // set a variable to true and use it in template 
    // debugger;
    this.isCiamClicked = sessionStorage.getItem('ciamClicked');
    let featureFlag = this.translationService.featureFlags;
    this.isProdEnvironment = featureFlag.general.tempProdFlag;
    this.reLoginForToken = sessionStorage.getItem('refreshTokenExpired');
    this.isCiamLinkingInProgress = sessionStorage.getItem('partcipationDetails') ? true : false;
    if (this.isCiamClicked || this.reLoginForToken) {
      this._awsauthService.signIn();
      this.signedIn = true;
    }



  }

}
