import { Story, StoryItemTypeCount, UserInfo } from '../../../../../app/components/interfaces/vehicle-page.interface';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MyStoriesService } from '../../services/my-stories.service';
import { VehicleInfo, VehicleInfoResponse } from '../../../../../app/components/interfaces/vehicle-page.interface';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-vehicle-page',
  templateUrl: './vehicle-page.component.html',
  styleUrls: ['./vehicle-page.component.scss']
})
export class VehiclePageComponent implements OnInit {

 pageNumber: number = 0;
 pageSize: number = 10;
 vehicleInfo!: VehicleInfo;
 userInfo !: UserInfo;
 storyItemTypeCount !: StoryItemTypeCount;
 stories!: any;
 storyDetails: any;
 public page: any = 0;
 vehicleId: any;
isShowSpinner: boolean = false;
storyArray : any[]= [];
map= new Map<any, any>();
isScrolled = false
isShowErrorMessage: boolean = false;

 constructor(private route: ActivatedRoute, private myStories: MyStoriesService) { }
  @HostListener("window: scroll", ['event'])
  onScroll(_event?: any): void {
   
    const footer = document.getElementById('footer-container');
    if (footer) {
   this.isScrolled= _event?.target?.scrollTop > 360;
      if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - footer.offsetHeight) {
        if (this.storyArray.length < 50 && this.page < this.pageNumber - 1) {
          this.loadMoreEventsButton();
          
        }
      }
    }
  }
  loadMoreEventsButton() {
    this.page += 1;
    this.getVehiceleDetails();
  }
  ngOnInit(): void {
  // this.vehicleInfo.vehicleUrl="../../../assets/images/car-fallback.png";
  // this.vehicleInfo.vehicleModel="Mercedes-Benz";
    this.route.params.subscribe((param)=>{
     this.vehicleId = param["vehicleId"];
    });
    this.getVehiceleDetails();
 

  }

  getVehiceleDetails( ){
    this.myStories.getVehicleDetails(this.vehicleId, this.page, 10).subscribe((data: VehicleInfoResponse)=>{        this.pageNumber = data.total_pages;
        this.vehicleInfo = data.vehicleInfo;
        this.userInfo = data.userInfo;
        this.storyItemTypeCount = data.storyItemTypeCount;
        this.isShowSpinner= true;

        // map is creating the key value pair as years as key and  object of stories as values

        data.stories.forEach((story)=>{
         
    
          let date = new Date(story.createdAt * 1000);
          let dateYear = date.getFullYear()
          // if the map has year already then it will just append the story value to the current map
          if(this.map.has(dateYear)){
            const Arrays = this.map.get(dateYear);
            Arrays.push(story);
            this.map.set(dateYear, Arrays);
            
          }
          // else it will add year and value
          else{
            this.map.set(dateYear, [story]);
            
          }
          // reversing the map 
          let storyMap = new Map([...this.map].reverse());
          this.stories= storyMap
         
        });
      },
      (error: any) => {
        this.isShowSpinner= true;
        this.isShowErrorMessage = true;

      }
       
     )
        
  }

}