export const defaultTranslationArray = [
    { key: 'BP_EM_ADD_COVERIMG', value: 'Add cover image' },
    { key: 'CP_LM_landingPage_explore', value: 'Explore' },
    { key: 'CP_LM_landingPage_searchText', value: 'Search places, landmarks, roads' },
    { key: 'CP_LM_landingPage_create', value: 'Create' },
    { key: 'CP_LM_landingPage_library', value: 'Library' },
    { key: 'CP_LM_landingPage_tracks', value: 'Track' },
    { key: 'CP_LM_landingPage_login', value: 'Login' },
    { key: 'CP_LM_landingPage_emotionTour', value: 'Emotion tour' },
    { key: 'CP_LM_landingPage_logout', value: 'Logout' },	
    { key: 'CP_LM_tourDetailsPage_highlights', value: 'Highlights' },
    { key: 'CP_LM_tourDetailsPage_memorial', value: 'Memorial' },
    { key: 'CP_LM_tourDetailsPage_fromStart', value: 'from start' },
    { key: 'CP_LM_tourDetailsPage_more', value: 'More' },
    { key: 'CP_LM_annotationDetailsPage_overview', value: 'Overview' },	
    { key: 'CP_LM_circuitDetailsPage_circuit', value: 'Circuit' },
    { key: 'CP_LM_circuitDetailsPage_address', value: 'Address' },
    { key: 'CP_LM_circuitDetailsPage_website', value: 'Website' },
    { key: 'CP_LM_circuitDetailsPage_storiesheader', value: 'Stories from the Community' },
    { key: 'CP_LM_trackDetailsPage_sectors', value: 'sectors' },	
    { key: 'CP_LM_pageNotFound_message', value: 'Cluster not available for the tour' },
    { key: 'CP_LM_defaultPage_message', value: 'Page not found' },
    { key: 'CP_LM_MainPage_welcome_line1', value: 'Welcome to me Stories' },
    { key: 'CP_LM_MainPage_welcome_line2', value: 'your companion for new adventures' },
    { key: 'CP_LM_MainPage_load_more', value: 'Load More' },
    { key: 'CP_LM_MainPage_by', value: 'by' },
    { key: 'CP_LM_MainPage_no_results', value: 'No Search Results' },  
    { key: 'CP_LM_footer_provider', value: 'Provider' },   
    { key: 'CP_LM_footer_privacy', value: 'Privacy' },    
    { key: 'CP_LM_MainPage_myprofile', value: 'My profile' },
    { key: 'CP_LM_MainPage_logout', value: 'Logout' },
    { key: 'CP_LM_NoExperience_message', value:`It's too quiet here`},
    { key: 'CP_LM_NoExperience_subMessage', value:'Get started on the MeStories App, capture and share your experiences with the entire world'},
    { key: 'CP_LM_EditProfile_subMessage', value:'To modify your Mercedes me account details, kindly visit the official Mercedes-Benz website'},
    { key: 'CP_LM_EditProfile_myProfile', value:'My Profile'},
    { key: 'CP_LM_EditProfile_message', value:'Introduce yourself to the community. These optional information will also update on your me Stories web and app profile.'},
    { key: 'CP_LM_EditProfile_nickName', value:'Nick Name'},
    { key: 'CP_LM_EditProfile_socialLink', value:'Social Link'},
    { key: 'CP_LM_EditProfile_description', value:'Description'},
    { key: 'CP_LM_EditProfile_urlMessage', value:'Enter a URL that you can send to other users via their profile'},
    { key: 'CP_LM_EditProfile_characterLimit', value:'Characters'},
    { key: 'CP_LM_EditProfile_cancel', value:'Cancel'},
    { key: 'CP_LM_EditProfile_update', value:'Update'},
    { key: 'CP_LM_CiamLink_message', value:'Your race data is in the works.'},
    { key: 'CP_LM_CiamLink_subMessage', value:`and we'll give you a shout when it's all set to go`},
    { key: 'CP_LM_MainPage_myStories', value:'My Stories'},    { key: 'CP_LM_ciamLink_message', value: 'Thank you for your participation in the event. You will be notified by e-mail when your recording is ready. You can close this window now.'},
    { key: 'CP_LM_ciamLink_title', value: 'Ongoing event'},
    { key: 'CP_LM_ciamConfirmPage_message', value: 'Please confirm the link between the mentioned event participation and Mercedes me ID below. All recordings of this event will be available for the given Mercedes me ID.'},
    { key: 'CP_LM_ciamConfirmPage_eventName', value: 'Event name & date'},
    { key: 'CP_LM_ciamConfirmPage_participantDetails', value: 'Event participation details'},
    { key: 'CP_LM_ciamConfirmPage_mercedesId', value: 'Your Mercedes me ID'},
    { key: 'CP_LM_ciamConfirmPage_connectMercedesMeId', value: 'Connect with other Mercedes me ID'},
    { key: 'CP_LM_ciamConfirmPage_confirm', value: 'Confirm'},
    { key: 'CP_LM_Anonymization_Popup_title', value: 'Anonymize event data'},
    { key: 'CP_LM_Anonymization_Popup_desc', value: 'If you choose to anonymize your event data, please be aware that all information associated with your events. Data will be permanently removed and cannot be recovered.'},
    { key: 'CP_LM_Anonymization_Video_content', value: 'Video content'},
    { key: 'CP_LM_Anonymization_Lb_ranking', value: 'Leaderboard ranking'},
    { key: 'CP_LM_Anonymization_Race_details', value: 'Race details'},
    { key: 'CP_LM_Anonymization_Confirmation', value: 'Are you sure you want to anonymize your data'},
    { key: 'CP_LM_Cancel', value: 'Cancel'},
    { key: 'CP_LM_Anonymization_Confirm_title', value: 'Anonymize now'},
    { key: 'CP_LM_Anonymization_Failed', value: 'Data anonymisation failed'},
    { key: 'CP_LM_Anonymization_Already_done', value: 'Data already anonymized'},
    { key: 'CP_LM_Anonymization_Success	Data', value: 'anonymisation successful.'},
    { key: 'CP_LM_Ok', value: 'Okay'},
    {key: 'CP_REFRESH_BTN', value: 'Reload'},
  {key: 'CP_CONTENT_NOT_FOUND', value: 'Sorry, we are unable to load content at the moment. Please try reloading the page.'},
  {key: 'CP_DATA_ANONYMIZATION_FAIL_MSG', value: 'Data anonymisation failed'},
  {key: 'CP_DATA_ANONYMIZED', value: 'Data already anonymized'},
  {key: 'CP_DATA_ANONYMIZED_SUCCESS', value: 'Data anonymisation successful.'},



    

]