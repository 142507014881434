import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-no-experiences',
  templateUrl: './no-experiences.component.html',
  styleUrls: ['./no-experiences.component.scss']
})
export class NoExperiencesComponent implements OnInit {
 translationData= [];
  constructor(private translationService: TranslationService) { }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

}