import { Pipe, PipeTransform } from "@angular/core";
import { defaultTranslationArray } from "./default-translation";
import { TranslationService } from "../services/translation.service";


@Pipe({
  name: "customTranslation",
})
export class CustomTranslationPipe implements PipeTransform {
  defaultEnTranslation = defaultTranslationArray;

  constructor(private translationService: TranslationService) {

  }


  transform(value: unknown, ...args: unknown[]): string {
    let translatedArray = args;
    
    let translatedValue: string = '';
    if (translatedArray) {
      
      translatedArray.forEach((item: any)=>{
        item?.filter((data: any) => {
          if (data.key.name === value) {
            translatedValue = data.content;
            return data;
          }
        });
      })
    }
    if (translatedValue.trim() === '') {
    this.defaultEnTranslation.forEach((data: any) => {
        if (data.key === value) {
          translatedValue = data.value;
        }
      });
    }

    return translatedValue;
  }
}
