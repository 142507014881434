import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MyStoriesRoutingModule } from './my-stories-routing.module';
import { VehiclePageComponent } from './pages/vehicle-page/vehicle-page.component';
import { ProfileComponentComponent } from './components/profile-component/profile-component.component';
import { CarComponentComponent } from './components/car-component/car-component.component';
import { MomentSharingComponent } from './components/moment-sharing/moment-sharing.component';
import { MyStoriesCardComponent } from './components/my-stories-card/my-stories-card.component';
import { NoExperiencesComponent } from './components/no-experiences/no-experiences.component';
import { MyStoriesListComponent } from './pages/my-stories-list/my-stories-list.component';
import { CardsComponent } from './components/moment-sharing/cards/cards.component';
import { TagsComponentComponent } from './components/tags-component/tags-component.component';
import { TranslationModule } from '../translation.module';
import { ProfileMobileViewComponent } from './components/profile-component/profile-mobile-view/profile-mobile-view.component';

@NgModule({
  declarations: [
    VehiclePageComponent,
    ProfileComponentComponent,
    CarComponentComponent,
    MomentSharingComponent,
    MyStoriesListComponent,
    NoExperiencesComponent,
    MyStoriesCardComponent,
    CardsComponent,
    TagsComponentComponent,
    VehiclePageComponent,
    ProfileMobileViewComponent
  ],
  imports: [
    CommonModule,
    MyStoriesRoutingModule,
    TranslationModule,
  ]
})
export class MyStoriesModule { }