<section class="responsive-profile" (click)="toggleOverlay()">
    <div class="profile-sec" >
        <div class="usr-img">
            <img src="../../../../../../assets/images/empty.png" class="profile-image" style="background-image: url({{profileDetails?.profileImage?profileDetails.profileImage:profileImage}});" alt="">
        </div>
        <div class="profile-name"> {{profileDetails.name}} </div>
    </div>
    <div class="big icon icon_arrow_down"></div> 
    
</section>
<div class="profile-section">
    <!-- Your profile section content here -->
  </div>


  <div class="container">
    <!-- Overlay -->
    <div class="overlay" [ngClass]="{'active': overlayActive}">
       
        <div class="profile-wrapper">
            <div class="overlay-menu" *ngIf="overlayActive" >
                <span (click)="toggleOverlay()" class="icon icon_close"></span>
                <!-- Add your menu items here -->
            </div>
            <div class="profile">
                <div class="profile-picture">
                    <img class="profile-image" style="background-image: url({{profileDetails?.profileImage?profileDetails.profileImage:profileImage}});" alt="">
                </div>
                <div class="profile-name">
                    <h6>{{profileDetails?.name}}</h6>
                    <!-- <small>{{profileDetails?.email}}</small> -->
                </div>
                <br>
                <div class="prof-info about"  *ngIf="profileDetails?.bio!=null">
                    <h6>ABOUT</h6>
                    <p>{{profileDetails?.bio}}</p>
                </div>
                <div class="prof-info social" *ngIf="profileDetails?.link?.length!=0">
                    <h6>SOCIAL</h6>
                   <a href="" target="_blank"> <p>{{profileDetails?.link}}</p> </a>
                </div>
            </div>
        </div>
     
    
    </div>
    
 
  </div>
  

