import { Component, Input, OnInit } from '@angular/core';
import { StoryItemTypeCount, VehicleInfo } from '../../../../../app/components/interfaces/vehicle-page.interface';

@Component({
  selector: 'app-car-component',
  templateUrl: './car-component.component.html',
  styleUrls: ['./car-component.component.scss']
})
export class CarComponentComponent implements OnInit {
@Input() vehicleInfo !: VehicleInfo;
@Input() storyItemTypeCount : StoryItemTypeCount = {} ; 
isExperienceEmpty : boolean=  false;
isCountOfExperienceIsOne: boolean = false;
isCountOfExperienceMore: boolean = false;
isCountOfMomentIsOne: boolean = false;
isCountOfMomentMore: boolean = false;
vehicleModel :any;
vehicleUrl: any;
@Input() isScrolled : any;
  constructor() { }

  ngOnInit(): void {
 this.vehicleModel = this.vehicleInfo?.vehicleModel === null ? "Mercedes-Benz" : this.vehicleInfo?.vehicleModel;
 this.vehicleUrl = this.vehicleInfo?.vehicleUrl === null ? "../../../../../assets/images/car-fallback.png" : this.vehicleInfo?.vehicleUrl;
 this.isExperienceEmpty = !Object.keys(this.storyItemTypeCount).length;
 this.isCountOfExperienceIsOne = this.storyItemTypeCount?.totalExperiences == 1;
 this.isCountOfExperienceMore = this.storyItemTypeCount?.totalExperiences !=0 && this.storyItemTypeCount?.totalExperiences != 1 && this.storyItemTypeCount?.totalExperiences!=null;
 this.isCountOfMomentIsOne = this.storyItemTypeCount?.totalMoments == 1;
 this.isCountOfMomentMore = this.storyItemTypeCount?.totalMoments !=0 && this.storyItemTypeCount?.totalMoments != 1 && this.storyItemTypeCount?.totalMoments!=null;

  }

}