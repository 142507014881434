<div class="usr-screen ciam-landing">
<div class="usr-event-card-rectangle">
    <div class="img-reg">
        <div class="image" style="background-image: url({{eventBanner?eventBanner:defaultBanner}});">
        </div>
    </div>
    <div class="info-txt-box">
        <div class="info-center">
            <h2>{{'CP_LM_ciamLink_title' | customTranslation: translationData }}</h2>
            <p class="your-race-data-is-in">
                <span>{{ 'CP_LM_ciamLink_message' | customTranslation: translationData }}</span>
            </p>
        </div>
        <div class="info-panel-bottom">
            <h2 class="Best-Time-Challenge">{{eventName}}</h2>
        </div>
    </div>
</div>

<ng-template #SpinnerLoads>
    <div class="spinner-border spinner">
    </div>
</ng-template>
</div>