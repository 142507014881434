import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-moment-sharing',
  templateUrl: './moment-sharing.component.html',
  styleUrls: ['./moment-sharing.component.scss']
})
export class MomentSharingComponent implements OnInit {
@Input() storyDetails : any ;
stories: any[]= [];
// value:any[]=[]
  constructor() {}

  ngOnInit(): void {
    this.stories = Array.from(this.storyDetails?.entries()).reverse();
    // this.value=this.stories[0][1]
  }

}