import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-provider-page',
  templateUrl: './provider-page.component.html',
  styleUrls: ['./provider-page.component.scss']
})
export class ProviderPageComponent implements OnInit {
  isNotFound: boolean = false;
  _language: any;
  translationData: any;
  content: string = 'CP_PROVIDER_TEMPLATE';
  isTranslationDone: boolean = false;

  constructor(private _translationService: TranslationService, private _actRoute: ActivatedRoute) { 
    const langId = this._actRoute.snapshot.paramMap.get('languageId');
    const userLang = navigator.language;
    if (!langId || !environment.supported_language.includes(langId)) {
      this.isNotFound = true;
    } else {
    this._language = langId && environment.supported_language.includes(langId) ? langId : (userLang && environment.supported_language.includes(userLang) ? userLang : 'en-GB');
      localStorage.setItem('language', this._language);
  }
  this._translationService.sharetranslationData.subscribe((data) => {
    this.translationData = data;
    this.isTranslationDone = true;
  });
  }

  ngOnInit(): void {
    this.content = 'CP_PROVIDER_TEMPLATE';
    if (!this.translationData?.length) {
      this.getTranslations(this._language);
    }
  }

  /**
   * @description This is done to call translations API in case of reloading screen
   * @param languageId 
   */
  private getTranslations(languageId: string): void {
    this._translationService
      .phraseApiCallForTranslations(languageId)

      .subscribe((allDeTranslationBasedOnlocle) => {   
        for(let i=0; i< allDeTranslationBasedOnlocle.length; i++) {
          this.translationData = this.translationData.concat(allDeTranslationBasedOnlocle[i]);
        }
        this.isTranslationDone = true;
        this._translationService.setTranslationData(this.translationData);
        this._translationService.sendSelectedLanguage(this._language);
        this._translationService.sendtranslationValue(this.translationData);
      }, err => {
        this.isTranslationDone = true;
      });
  }

}
