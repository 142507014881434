import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  language: any;
  supportedLanguage = environment.supported_language;
  private _participantLocaleSubject = new BehaviorSubject<any>('de-DE');  
  
  constructor() { 
    
  }

  getLanguage() {
    const selectedLanguage = localStorage.getItem('language');
    const defaultBrowserLanguage = navigator.language;
    const supportedLocales: any = {
      'en': 'en-GB',
      'de': 'de-DE',
      'fr': 'fr-FR',
      'it': 'it-IT',
      'ko': 'ko-KR',
      'cs': 'cs-CZ',
      'es': 'es-ES'
    }
    if(selectedLanguage && this.supportedLanguage.includes(selectedLanguage)) {
      this.language = selectedLanguage;
    } else if(defaultBrowserLanguage && this.supportedLanguage.includes(defaultBrowserLanguage)) {
      const selectedLang = (lang: string) => supportedLocales[lang];
      this.language = selectedLang(defaultBrowserLanguage);
    } else {
      this.language = environment.supported_language[0];
    }
    this._participantLocaleSubject.next(this.language);
    return this.language;
  }

  
  public set participantLocale(locale : string) {
    this._participantLocaleSubject.next(locale);
  }

  
  public get participantLocale() : any {
    return this._participantLocaleSubject.asObservable();
  }
  
  
}
