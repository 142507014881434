import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslationService } from '../services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-no-route',
  templateUrl: './no-route.component.html',
  styleUrls: ['./no-route.component.scss']
})
export class NoRouteComponent implements OnInit {
  isUser: any;
  isUserLoggedIn: any;
  mySubscription: any;
  translationData = []

  constructor(private router: Router, private translationService: TranslationService) {
    let access_token: any;

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(event.url !== '/temp-login'){
          localStorage.setItem('routeUrl', event.url);
        }
        this.isUser = localStorage.getItem('userLoggedIn');
        access_token = localStorage.getItem('access_token');
        this.isUserLoggedIn = localStorage.getItem('isUserLoggedIn');

        if (!access_token && !this.isUser && !this.isUserLoggedIn && !environment.production) {
          this.router.navigateByUrl('/temp-login');
        } else {
          localStorage.removeItem('routeUrl');
        }
      }
    });
  }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

}
