<section *ngIf="!signedIn" class="vh-100" style=" background-color: #000;">
  <div class="py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="login-box">
        <div class="card shadow-2-strong" style="border-radius: 1rem">
          <div class="card-body p-5 text-center">
            <h3 class="mb-5">Sign in</h3>
            <form (ngSubmit)="login()">
              <div class="form-item-login">
                <label class="form-label" for="name">UserName</label>
                <input
                  type="text"
                  id="name"
                  class="form-control form-control-lg"
                  name="userName"
                  [(ngModel)]="userName"
                />
              </div>

              <div class="form-item-login">
                <label class="form-label" for="password">Password</label>
                <input
                  type="password"
                  id="password"
                  class="form-control form-control-lg"
                  name="password"
                  [(ngModel)]="password"
                />
              </div>

              <!-- Checkbox -->
              <div class="form-item-checkbox">
                <input type="checkbox" value="" id="form1Example3" />
                <label class="form-check-label" for="form1Example3">
                  Remember password
                </label>
              </div>

              <button class="beta full-w" type="submit">
                Login
              </button>

              <hr class="my-4" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>