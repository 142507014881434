<ng-container *ngIf="!isPageNotFound">
    <div id="parent" class="page-container">
        <div id="imageContainer" class="next-section" role="main">
        </div>
        <div id="mainMessage" class="content title-position" role="main">
            <h1>{{'CP_LM_MainPage_welcome_line1' | customTranslation: translationData}} </h1>
            <h1>{{'CP_LM_MainPage_welcome_line2' | customTranslation: translationData}} </h1>
            <div class="message-button-block">
                <button *ngIf="!currentUser && !isProd" (click)="triggerLogin()"
                    class="login-button"><span>{{'CP_LM_landingPage_login' | customTranslation:
                        translationData}}</span></button>
                <button (click)="goToExplore()" class="login-button" *ngIf="!isProd"><span>{{'CP_LM_landingPage_explore' |
                        customTranslation: translationData}}</span><span><img alt="image" class="nav-arrow"
                            src="assets/images/navigation-arrow-up-right.png" /></span> </button>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="participantDataToAnonymize?.length">
    <app-anonymize-popup (closeAnonymizationPopup)="closeAnonymizationPopup($event)"></app-anonymize-popup>
</ng-container>

<ng-container *ngIf="isPageNotFound">
    <app-no-route ></app-no-route>
</ng-container>


