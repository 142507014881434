import { Component, OnInit, Renderer2 } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  translationData = [];
  isFooterAvailable = environment.feature.isfooterAvailable;
  constructor(private translationService: TranslationService) {}

  ngOnInit() {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

  


}
