<div class="car-moments-sharing">
    <div class="moment-sharing-section" *ngFor="let year of stories;">
      <div class="year">
        <small>{{year[0]}}</small>
      </div>
      <ul class="moments">
        <ng-container>
        <li class="moment" *ngFor="let storyCard of year[1] | keyvalue">
          <div class="moment-inner">
            <app-cards [story]="storyCard.value"></app-cards>
          </div>
        </li>
      </ng-container>
      </ul>
  
    </div>
   
  </div>