

<div class="profile-wrapper">
    <div class="profile">
        <div class="profile-picture">
            <img class="profile-image" style="background-image: url({{profileDetails?.profileImage?profileDetails.profileImage:profileImage}});" alt="">
        </div>
        <div class="profile-name">
            <h6>{{profileDetails?.name}}</h6>
            <!-- <small>{{profileDetails?.email}}</small> -->
        </div>
        <br>
        <div class="prof-info about"  *ngIf="profileDetails?.bio!=null">
            <h6>ABOUT</h6>
            <p>{{profileDetails?.bio}}</p>
        </div>
        <div class="prof-info social" *ngIf="profileDetails?.link?.length!=0">
            <h6>SOCIAL</h6>
           <a href="" target="_blank"> <p>{{profileDetails?.link}}</p> </a>
        </div>
    </div>
</div>