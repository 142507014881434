import { Component, Input, OnInit } from '@angular/core';
import { AppServicesService } from 'src/app/services/app-services.service';
import { Title, Meta } from '@angular/platform-browser';

interface CustomWindow extends Window {
  // SetPopUpInfo: any;
}

const createUnityInstance = (window as any)['createUnityInstance'];
declare let TourMapAPI: any;
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-track-details-page',
  templateUrl: './track-details-page.component.html',
  styleUrls: ['./track-details-page.component.scss'],
})
export class TrackDetailsPageComponent implements OnInit {
  @Input() public trackGuid: any;
  @Input() public destination: any;
  public access_Token: any;
  public trackData: any = [];
  public apiKey: any;
  title: any;
  owner: any;
  createdDate: any;
  trackLength: any = '';
  mapHeight = 'calc(100vh - 112px)';
  mapWidth = 'calc(100vw - 820px)';
  iconsList = [];
  annotationsData: any;
  emotionTourData: any;
  public track:any;
  showNotFound: boolean = false;
  translationData = [];
  constructor(private appservice: AppServicesService, private titleService: Title, private metaService: Meta,
    private translationService: TranslationService) {}

  setSEOData(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.showNotFound = false;
    this.apiKey = environment.apikey;

    this.appservice.dummyToken().subscribe({
      next: (data: any) => {
        if (data['access_token']) {
          this.access_Token = data.access_token;
          this.getAnnotations();
        }
      },
    });
  }
  
  getAnnotations() {
    this.appservice
      .getClusterAnnotations(this.access_Token, this.trackGuid)
      .subscribe({
        next: (track: any) => {
      this.track=track;
          
          this.trackData = this.track.features.filter((item: any) => {
            if(item.properties){
              if (item.properties.landmark_type?.toLowerCase() === 'route') {
                let description = item.properties.description ? item.properties.description : item.properties.name
                this.setSEOData(item.properties.name, description);
                return item;
              }
            }            
          });
          this.trackData = this.trackData[0];
          this.createdDate = new Date(
            this.trackData.properties.createdDate?.replace(/\s/, 'T')
          ).toLocaleDateString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });
          this.trackLength = this.trackData.properties.trackLength / 1000;
          this.track.features.filter((data: any) => {
            if(data.properties){
              if (data.properties.landmark_type?.toLowerCase() === 'route') {
                if (
                  data.geometry.coordinates[0][0] ===
                  data.geometry.coordinates[
                    data.geometry.coordinates.length - 1
                    ][0] &&
                    data.geometry.coordinates[0][1] ===
                    data.geometry.coordinates[
                      data.geometry.coordinates.length - 1
                    ][1]
                ) {
                  this.track.isRoundTrip = true;
                }
  
                return data;
              }
            }            
          });
          this.emotionTourData = this.track;
          this.annotationsData = this.track.features.filter((data: any) => {
            if(data.properties){
              if (
                data.geometry.type !== 'Polygon' &&
                data.properties.landmark_type?.toLowerCase() !== 'route' &&
                data.properties.landmark_type?.toLowerCase() !== 'origin' &&
                data.properties.landmark_type?.toLowerCase() !== 'destination'
              ) {
                data.properties.route_position =
                data.properties.route_position / 1000;
                  if(data.properties.annotations?.length === 1) {
                    data.isSingleton = true;
                  } else {
                    data.multiple = true;
                  }
                return data;
              }
            }
            
          });
          this.annotationsData = this.annotationsData.sort(
            (first: any, second: any) =>
              first.properties.distance_from_origin - second.properties.distance_from_origin
          );
          this.trackData = this.track[0];
         },error: (error) => {
          if(error.error['error-code']){
            this.showNotFound = true;
          }
        },
      });
  }

}
