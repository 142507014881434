import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { LanguageService } from 'src/app/services/language.service';

interface participantDetails {
  participantId: string, participantKey: string
}

@Component({
  selector: 'app-anonymize',
  templateUrl: './anonymize.component.html',
  styleUrls: ['./anonymize.component.scss']
})
export class AnonymizeComponent implements OnInit {

  participantInfo: string = '';
  partcipantDetails:participantDetails = {participantId: '', participantKey: ''};
  language = 'en-GB'

  constructor(
    private route: ActivatedRoute, 
    private awsAuth: AwsAuthService, 
    private router: Router,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.route?.params?.subscribe((param) => {
      this.participantInfo = atob(param['partipationInfo']);
      let participantInfo = this.participantInfo.split('/');
      this.partcipantDetails.participantId = participantInfo[0];
      this.partcipantDetails.participantKey = participantInfo[1];
    });
    
    sessionStorage.setItem('anonymizeParticipant', JSON.stringify(this.partcipantDetails));

    if(this.awsAuth.getUserLoginStatus()) {
      this.router.navigateByUrl('/'+this.language+'/home');
    } else {
      this.awsAuth.currentSession().then(
          (_res) => {
            let accessToken = _res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            let jwt_expires_in = accessToken.getExpiration();
            let refreshToken = _res.getRefreshToken();
            let jwtRefreshToken = refreshToken.getToken();
            let idToken = _res.getIdToken();
            let jwtIdToken = idToken.getJwtToken();
            let fullData = {
              id_token: jwtIdToken,
              access_token: jwt,
              refresh_token: jwtRefreshToken,
              expires_in: jwt_expires_in,
              token_type: "Bearer",
            };
            this.awsAuth.setUserLoginStatus(true);
            this.awsAuth.setCurrentUserToken(fullData);
            this.router.navigateByUrl('/'+this.language+'/home');
          },
          (_err) => {
            console.log(_err);
            this.awsAuth.signIn();
          }
      );
    }
  }

}
