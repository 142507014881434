import { Component, ElementRef, Input, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-my-stories-card',
  templateUrl: './my-stories-card.component.html',
  styleUrls: ['./my-stories-card.component.scss']
})
export class MyStoriesCardComponent implements OnInit {

  eventBanner = "";
  defaultBanner = "../../../assets/images/banner.png";
  volume: boolean = false;
  @Input() public story: any ;
  @Input() numberOfImages: number = 0;
  
  public currentIndex: number = 0;
  date: any;
  raceShareTime: any;
    constructor(private el: ElementRef, private renderer: Renderer2,
      ) {}
    ngOnInit(): void {  
     
      this.date = new Date(this.story?.dateTime *1000);
      this.raceShareTime= new Date(this.story?.raceData?.raceStartTime * 1000);
      

    }

    scroll(direction: number) {
      this.currentIndex = (this.currentIndex + direction + this.story.media.length) % this.story.media.length;
      const mediaContainer = this.el.nativeElement.querySelector('.media-container');
      if (mediaContainer ) {
        const scrollTo = this.currentIndex * mediaContainer.clientWidth;
        mediaContainer.scrollLeft = scrollTo;
      }
    }
}