import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AwsAuthService } from './services/aws-auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private aws: AwsAuthService) {}
  accessToken:any
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    this.accessToken=localStorage.getItem('token')
    const isMyStoryApi= request.url.startsWith(environment.myStoriesUrl);
    const isApiUrl = request.url.startsWith(environment.serverUrl+'api/v2');
    const isprofileUrl = request.url.startsWith(environment.profileUrl);
    const isUpdateProfile = request.url.startsWith(environment.updateProfile);
    if (isApiUrl || isMyStoryApi || isprofileUrl || isUpdateProfile) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.accessToken}`
        }
      });
    }

    return next.handle(request);
  }
}
