import { Component, Input, OnInit } from '@angular/core';
import { UserInfo } from '../../../../../app/components/interfaces/vehicle-page.interface';

@Component({
  selector: 'app-profile-component',
  templateUrl: './profile-component.component.html',
  styleUrls: ['./profile-component.component.scss']
})
export class ProfileComponentComponent implements OnInit {
@Input()  profileDetails !: UserInfo;
profileImage = "../../../../../assets/images/profile-fallback.png";
  constructor() { }

  ngOnInit(): void {
  }

}